import React from "react";
import AboutSec from "../../assets/images/about/aboutsec1.png";
import AboutBg from "../../assets/images/about/aboutusBg.png";
import { ReactComponent as Border } from "../../assets/svg/border.svg";
import { ReactComponent as BgIcon } from "../../assets/svg/bgIcon.svg";
import { ReactComponent as LinkedinLogo } from "../../assets/svg/linkedinLogo.svg";
import { ReactComponent as LinkedinLogoMobile } from "../../assets/svg/linkedinLogoMobile.svg";
import TeamOne from "../../assets/images/about/team1.png";
import TeamTwo from "../../assets/images/about/team2.png";
import TeamThree from "../../assets/images/about/team3.png";
import LogoOne from "../../assets/images/logos/logo1.png";
import LogoTwo from "../../assets/images/logos/logo2.png";
import LogoThree from "../../assets/images/logos/logo3.png";
import LogoFour from "../../assets/images/logos/logo4.png";
import LogoFive from "../../assets/images/logos/logo5.png";
import LogoSix from "../../assets/images/logos/logo6.png";
import Closing from "../../components/Closing";

export default function About() {
  const teamContent = [
    {
      name: "Ali Hussein",
      img: TeamOne,
      url: "https://www.linkedin.com/in/justanotherali/",
    },

    {
      name: "Rishika Lulla",
      img: TeamThree,
      url: "https://www.linkedin.com/in/rishika-lulla-singh-2761a176?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    },
    {
      name: "Ridhima Lulla",
      img: TeamTwo,
      url: "https://www.linkedin.com/in/ridhima-l-0973158b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    },
  ];
  const valueContent = [
    {
      title: "Innovation First",
      content: `Continually pushing the limits of what can be achieved in the world  
        of AI technology.`,
    },
    {
      title: "Ethics at the Core",
      content: `Determined to prioritize the
    highest ethical principles
    across all our AI innovations.`,
    },
    {
      title: "Transparency in Data",
      content: `Our commitment to transparent communication underlines
    every aspect of our work.`,
    },
    {
      title: "Embracing Change",
      content: `Our adaptability is our strength, evolving with the ever-shifting 
    AI landscape.`,
    },
    {
      title: "Continuous Learning",
      content: `We nurture a culture that values curiosity, growth, and ongoing education.`,
    },
    {
      title: "Purposeful Drive",
      content: `Driven by passion, energized for success, achieving our goals with zeal.`,
    },
  ];
  const valueSection = (title, content) => {
    return (
      <div className="border-igreen border-l md:px-10 px-4 md:w-1/3 w-1/2 mt-10 md:mt-20">
        <p className="md:text-3xl	text-xl font-manropemedium">{title}</p>
        <p className="md:text-xl text-xs font-plexlight	pt-2">{content}</p>
      </div>
    );
  };

  const teamSection = (name, img, url) => {
    return (
      <div className="relative flex">
        <a href={url} target="_blank" rel="noopener noreferrer">
          <img src={img} alt={name} />
          <div
            className="about-cardbottom absolute backdrop-blur-md bottom-0 flex font-semibold 
          justify-between px-1 md:px-4 py-1 lg:py-2.5 text-white lg:text-3xl md:text-xl text-[8px] sm:text-[11px] w-full cursor-pointer"
          >
            <p className="justify-center flex-col flex">{name}</p>
            <span className="bg-white md:flex hidden  h-3.5 md:h-[40px] w-auto items-center justify-center rounded-full px-2">
              <LinkedinLogo />
            </span>
            <span className="bg-white md:hidden flex h-auto w-[14px] sm:w-auto items-center justify-center rounded-full px-1">
              <LinkedinLogoMobile />
            </span>
          </div>
        </a>
      </div>
    );
  };
  return (
    <>
      <div
        className="bg-cover max-w-[1800px] mx-auto"
        style={{ backgroundImage: `url(${AboutBg})` }}
      >
        <section className="xl:container 2xl:mx-auto xl:mx-16 lg:mx-12 md:mx-9 mx-6 pt-[120px] flex flex-col justify-center">
          <div className="md:mt-8 mt-0">
            <p className="text-white text-[24.5px] font-semibold md:text-6xl leading-[35px] md:leading-[81px]">
              <span className="gradient-head-main">A Big Bang in AI.</span>
              <br></br>
              Our cutting-edge systems<br></br>
              are shaping the <span className="text-igreen"> future of </span>
              <br></br>
              <span className="text-igreen"> Artificial Intelligence!</span>
            </p>
          </div>
          <div></div>
        </section>

        <section className="xl:container mx-6 xl:mx-auto md:mx-9 mb-32 lg:pb-0 lg:mx-12 md:pt-[120px] bg-cover relative">
          <div className="flex items-center justify-center ">
            <div className="top-[10px] w-full xl:w-2/3">
              <img
                src={AboutSec}
                className="w-full lg:w-[560px] xl:w-[780px] h-auto"
                alt="About-"
              />
            </div>
            <div className="xl:w-1/3 hidden lg:flex items-start justify-end pb-[120px]">
              <BgIcon />
            </div>
            <div className="text-white about-box xl:p-20 md:p-10 p-5 md:bottom-[-15%] bottom-[-195px] lg:bottom-[5%] lg:right-0 xl:bottom-[10%] absolute xl:right-0 lg:w-3/5  w-[97%] xl:w-3/5">
              <p className="md:text-5xl text-[20px]">About Immerso.ai</p>
              <div className="py-4 overflow-hidden">
                <Border />
              </div>
              <p className="md:text-2xl text-sm">
                Immerso.ai, part of a multi billion-dollar Eros Investments Group, is
                at the forefront of entertainment, generative AI technologies,
                and AI infrastructure, aiming to transform the generative media
                landscape with intellectual property and data. Our focus is on
                advanced models and robust multimodal AI solutions, serving
                diverse industries and use cases.
              </p>
            </div>
          </div>
        </section>

        <section className="mx-auto pt-28">
          <div className="bg-gradient-green text-white font-semibold lg:py-24 md:py-10 py-6 text-xl md:text-3xl lg:text-6xl text-center">
            <p>Pioneering Beyond the Horizon</p>
          </div>
        </section>

        <section className="xl:container lg:mx-12 md:mx-9 mx-4 xl:mx-auto">
          <p
            className="md:text-5xl text-3xl bg-gradient-to-r mt-16 from-white to-white bg-clip-text text-transparent leading-loose"
            style={{
              backgroundImage:
                "linear-gradient(182deg, #FFFFFF 13.26%, rgba(255, 255, 255, 0) 142.96%)",
            }}
          >
            Our Values
          </p>
          <div className="flex flex-wrap text-white">
            {valueContent.map((item) => {
              return valueSection(item.title, item.content);
            })}
          </div>
        </section>

        <section className="xl:container xl:mx-auto lg:mx-12 md:mx-9 mx-4 pt-20 md:pt-40 lg:pt-52">
          <p className="md:text-2xl text-[12px] text-igreen200 leading-10 py-0	md:py-2.5">
            MEET OUR TEAM
          </p>
          <p className="text-white text-xl sm:text-3xl md:text-5xl lg:text-7xl py-2.5">
            Meet Our Innovators
          </p>
          <p
            className="text-xl sm:text-3xl md:text-5xl lg:text-7xl bg-gradient-to-r leading-normal from-white to-white bg-clip-text text-transparent pb-3"
            style={{
              backgroundImage:
                "linear-gradient(182deg, #FFFFFF 13.26%, rgba(255, 255, 255, 0) 142.96%)",
            }}
          >
            The Minds Behind the Magic
          </p>
          <div className="flex justify-between pt-10 gap-3 md:pt-20">
            {teamContent.map((item) => {
              return teamSection(item.name, item.img, item.url);
            })}
          </div>
        </section>

        <section className="xl:container  xl:mx-auto lg:mx-12 md:mx-9 mx-4 lg:pt-52 md:pt-40 pt-20 text-center">
          <p className="text-igreen200 md:text-2xl text-[12px]	pb-3">
            MEET OUR PARTNERS
          </p>
          <p
            className="lg:text-6xl md:text-4xl text-[20px] sm:text-2xl bg-gradient-to-r from-white to-white bg-clip-text text-transparent leading-loose pb-2 md:pb-7"
            style={{
              backgroundImage:
                "linear-gradient(182deg, #FFFFFF 13.26%, rgba(255, 255, 255, 0) 142.96%)",
            }}
          >
            Innovating Through Collaboration
          </p>

          <div className="lg:py-36 md:py-20 py-10 flex-wrap xl:justify-between gap-1 md:gap-4 justify-center items-center lg:justify-center flex">
            <div className="w-[100px] md:w-auto">
              <img src={LogoOne} alt="companies-logo" />
            </div>
            <div className="w-[100px] md:w-auto">
              <img src={LogoTwo} alt="companies-logo" />
            </div>
            <div className="w-[100px] md:w-auto">
              <img src={LogoThree} alt="companies-logo" />
            </div>

            <div className="w-[100px] md:w-auto">
              <img src={LogoSix} alt="companies-logo" />
            </div>
            <div className="w-[100px] md:w-auto">
              <img src={LogoFour} alt="companies-logo" />
            </div>
            <div className="w-[80px] md:w-auto">
              <img src={LogoFive} alt="companies-logo" />
            </div>
          </div>
        </section>
        <Closing
          text="Empowering"
          subText={`Tomorrow’s Possibilities`}
          ctaText="Start your free trial now!"
        />
      </div>
    </>
  );
}
