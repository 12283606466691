import React from 'react'
export default function Banner({ bannerVideo, banner, bgBanner, title }) {
    return (<>
        <section className='bg-[#1D085D] bg-contain flex bg-no-repeat mt-10 md:mt-0 px-4 bg-right product_bannerSec' style={bgBanner && { backgroundImage: `url(${bgBanner})`, minHeight:'' }}>
            <div className='xl:container lg:w-full lg:mx-6 lg:mx-auto flex justify-between'>
                <div className=' flex flex-col justify-center px-2 lg:px-0 lg:w-1/2 lg:px-6'>
                    <p className='text-white banner-text
                    text-xs md:text-2xl lg:text-4xl 
                    font-semibold font-manropemedium 
                    leading-[1.3] whitespace-pre-line bannerSec_title'>{title}</p>

                </div>
                {(banner || bannerVideo ) && <div className='py-4 md:py-28 lg:py-28 xl:py-14 w-1/2 lg:w-1/2 flex'>
                    {banner && <img src={banner} alt="Sec Four Banner" />}
                    {bannerVideo && <video  className="w-full" autoPlay muted loop>
                        <source src={bannerVideo} type="video/mp4" />
                    </video> }
                </div>}
            </div>
        </section>
    </>)
}