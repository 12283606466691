import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as Chip } from "../../../assets/images/about/chip.svg";
import AboutHead from "../../../assets/images/about/AboutTop.svg";
import AboutFoot from "../../../assets/images/about/AboutBottom.svg";
import BgImageMob from "../../../assets/images/about/aboutMob.png";
import Button from "../../../components/Button";
import { motion, useScroll, useMotionValueEvent } from "framer-motion";

export default function About() {
  // const isVisible = useIsVisible(refAbout);
  const [height, setHeight] = useState(0);
  const [maxHeight, setMaxHeight] = useState(0);
  const handleKnowMore = () => {
    window.location.href = "/about";
  };

  const refAboutSec = useRef(null);
  const refAbout = useRef();
  const heightRef = useRef(null);

  const { scrollYProgress } = useScroll({
    target: refAbout,
    offset: ["start center", "center start"],
  });

  useMotionValueEvent(scrollYProgress, "change", (latest) => {
    console.log("Page scroll: ", latest);
    // height = `${latest*10}px`
    setHeight(`${latest * 100}vh`);
    setMaxHeight(heightRef.current.getBoundingClientRect().height);
  });

  useEffect(() => {
    console.log("heightRef:", heightRef.current.getBoundingClientRect().height);
  }, []);

  return (
    <>
      <section
        className="max-h-[1180px] relative mt-10 md:mt-0"
        ref={refAboutSec}
      >
        <div className="max-h-[1200px] xl:container  lg:h-max xl:h-screen mb-10 mx-auto flex flex-col justify-center">
          <div className="flex flex-row justify-center px-10" ref={refAbout}>
            <img src={AboutHead} />
          </div>

          <motion.div
            className="overflow-hidden about-animate-div"
            style={{ height: height, maxHeight: maxHeight }}
          >
            <div className="overflow-hidden " ref={heightRef}>
              <div
                className="flex justify-between py-4 lg:py-16"
                style={{
                  backgroundImage: `url(${BgImageMob})`,
                  backgroundSize: "cover",
                  margin: "0px 20px",
                }}
              >
                <div className=" hidden items-center md:flex px-10 xl:px-20 w-2/5 xl:w-1/2">
                  <Chip />
                </div>
                <div className="flex flex-col md:pr-12 md:justify-center xl:w-1/2  px-6 w-full ">
                  <div>
                    <p className="text-xs lg:text-xl  font-plexlight  tracking-wide text-left text-igrey leading-loose">
                      An imaginative deep tech and generative AI company, under
                      the Eros Investment Group. Our mission is to pioneer the
                      generative media landscape, leveraging our rich
                      intellectual property and licensed data. At the heart of
                      Immerso.ai lies a commitment to cultivating advanced
                      foundation models, enhancing value for users, creators,
                      and brands alike. With a focus on multimodal foundation
                      and specialized models, we cater to diverse industry
                      needs. Immerso.ai also represents the future of deep
                      technology, encompassing state-of-the-art cloud & GPU
                      infrastructure, an AI university, and a thriving hub for
                      generative AI startups and enterprises.
                    </p>
                  </div>
                  <div className="lg:mb-10">
                    <Button
                      variant="gradient"
                      onClick={handleKnowMore}
                      text="Know More"
                    />
                  </div>
                  <div className="pt-6 md:hidden md:pt-0"></div>
                </div>
              </div>
            </div>{" "}
          </motion.div>

          <div className="flex flex-row justify-center px-10">
            <img src={AboutFoot} loading="lazy" alt="about-foot-img"/>
          </div>
        </div>
      </section>
    </>
  );
}
