import React from 'react'
import HeroBanner from '../../../assets/images/product/iImage/iImageHero.mp4'
import SectionBanner from '../../../assets/images/product/iImage/iImageBanner.png'
import DescBanner from '../../../assets/images/product/iImage/iImageDesc.png'
import FeatureOne  from '../../../assets/images/product/iImage/iImage_feat1.svg'
import FeatureTwo  from '../../../assets/images/product/iImage/iImage_feat2.svg'
import FeatureThree  from '../../../assets/images/product/iImage/iImage_feat3.svg'
import FeatureFour  from '../../../assets/images/product/iImage/iImage_feat4.svg'
import FeatureFive  from '../../../assets/images/product/iImage/iImage_feat5.svg'
import Banner from '../sections/Banner';
import Hero from '../sections/Hero';
import Desc from '../sections/Desc';
import Feature from '../sections/Feature';
import Faq from '../sections/FAQ';
import CarouselWrapper from '../sections/CarouselWrapper';
import Closing from '../../../components/Closing';

export default function Iimage() {
    const featureContent = [
        {
            "icon": FeatureOne,
            "title": `Stunning Designs`,
            "content": `Experience the power of AI that transform your prompts into 
            stunning visuals.`
        },
        {
            "icon": FeatureTwo,
            "title":"Your Story, Our \nImages",
            "content": `Masterfully bringing narratives to life with unmatched visual brilliance.`
        },
        {
            "icon": FeatureThree,
            "title":`Pursuit of Quality`,
            "content": `Elevating every image with superior craftsmanship and detail, ensuring each creation is a testament.`
        },
        {
            "icon": FeatureFour,
            "title":`Low image \nGeneration time`,
            "content": `Effortlessly merging user-friendly design with professional-level artistry in seconds.`
        },
        {
            "icon": FeatureFive,
            "title":`Diverse choices`,
            "content":  `Offering a wide array of creative options to suit every imaginative need and style preference.`
        },
        
    ]
    const faqContent = [
        { question: 'How does the iImage model works?', 
        answer: 'The iImage model works by deploying advanced proprietary algorithms on licensed training data to give you stunning outputs.' },
        { question: 'Can I use iImage on desktop and mobile ?', 
        answer: 'Yes, iImage can typically be used on both desktop and mobile platforms, provided the service is designed to be compatible across various devices and operating systems.' },
        { question: 'Can I collaborate with others on iImage?', 
        answer: 'Yes, iImage often allows collaboration with others, offering features like shared projects and real-time editing, depending on its specific functionality and design.' },
        // Add more FAQ items as needed
    ];

    return (
        <>
            <div className='max-w-[1800px] mx-auto'>
            <Hero isVideo={true}  banner={HeroBanner} title={`Visualize Anything You Can Imagine`} />
            <Desc banner={DescBanner} content={`Effortlessly transform an existing image into an enhanced image with stunning visuals and aesthetics using our AI image generator from text. Easily create an image from scratch with our AI image generator by entering descriptive text and your preferred image prompt.`}/>

            <Feature title={`Crafting Your Imagination into \nVisual Reality`} content={featureContent}/>
      
            <Banner bgBanner={SectionBanner} title={`Crafting Phrases to Frames: \nMake Your Prompts Erupt \nwith Life`} />
            <Faq faqContent={faqContent}/>
            <CarouselWrapper productName='iImage'/>
            <Closing
				text='Craft Visuals that'
				subText='Reflect Your Thoughts'
				ctaText='Start your free trial now!'
			/>
            </div>
        </>
    )
}
