import React, { useRef } from "react";
import { useIsVisible } from "../../../../utils/hooks/useIsVisible";
import HeroBgImage from "../../../../assets/images/home/bgoverlay1.svg";
import HeroBgVideo1 from "../../../../assets/images/home/bgvideo1.mp4";
import HeroBgVideo2 from "../../../../assets/images/home/bgvideo2.mp4";
import HeroGif1 from "../../../../assets/images/home/bggif1.gif";
import HeroGif2 from "../../../../assets/images/home/bggif2.gif";
import Button from "../../../../components/Button";

const DYNAMIC_CONTENT_MAPPING = {
  1: {
    image: HeroBgImage,
    video: HeroBgVideo1,
    gif: HeroGif1,
    title: "The Big Bang of AI",
    subtTitle: "Right here. Right now!",
    description:
      "Innovating at the forefront of AI technology, the company focuses on consumer services, creator tools, AI infrastructure, and research. It equips consumers and enterprises with user-friendly, cutting-edge tools and services that bring creative ideas to life while crafting the future of AI.",
  },
  2: {
    image: HeroBgImage,
    video: HeroBgVideo2,
    gif: HeroGif2,
    title: "The Big Bang of AI",
    subtTitle: "Right here. Right now!",
    description:
      "Innovating at the forefront of AI technology, the company focuses on consumer services, creator tools, AI infrastructure, and research. It equips consumers and enterprises with user-friendly, cutting-edge tools and services that bring creative ideas to life while crafting the future of AI.",
  },
};

export default function DynamicHero({ scrollToProducts }) {
  const ref1 = useRef();
  const isVisible = useIsVisible(ref1);

	const count = JSON.parse(localStorage.getItem("refreshCount")) ?? 1;
	return (
		<div ref={ref1}>
			<div class='max-w-[1800px] right-0 mx-auto  absolute top-0 left-0 w-full h-[65rem] overflow-hidden backdrop-blur-2xl'>
				<video
					className='min-w-full min-h-full absolute object-cover'
					autoPlay
					muted
					loop
				>
					<source
						src={DYNAMIC_CONTENT_MAPPING[count]?.video}
						type='video/mp4'
					/>
				</video>
			</div>
			<div className='relative justify-center items-center xl:top-60 lg:top-64 md:top-80 flex'>
				<div className='md:basis-1/2  lg:flex flex-row justify-center -mt-12'>
					<img
						src={DYNAMIC_CONTENT_MAPPING[count]?.gif}
						alt='loading...'
						className="h-auto xl:w-auto lg:w-[25rem] md:w-[20rem]"
					/>
				</div>
				<div className='md:basis-1/2  xl:pl-0 md:w-full mt-[-30px] flex flex-col'>
					<div
						className={`${
							isVisible && "animate-slideup [--slidein-delay:300ms] opacity-0"
						} text-[44px] lg:text-[58px] pb-1 lg:pb-2 xl:text-[70px] font-extrabold  font-manropebold tracking-tight text-white inline-block bg-clip-text text-transparent`}
					>
						{DYNAMIC_CONTENT_MAPPING[count]?.title}
					</div>
					<div
						className={`${
							isVisible && "animate-slideup [--slidein-delay:300ms] opacity-0"
						} text-2xl xl:text-[45px] lg:text-3xl  pb-4 pt-0 xl:pt-3 font-normal font-manropemedium tracking-tight text-white  inline-block bg-clip-text text-transparent`}
					>
						{DYNAMIC_CONTENT_MAPPING[count]?.subtTitle}
					</div>
					<div
						className={`${
							isVisible && "animate-slideup  [--slidein-delay:300ms] opacity-0"
						} opacity-0 xl:text-lg lg:text-[16px] text-[13px] sm:w-full xl:w-2/3 text-wrap my-2 lg:my-8 text-igrey tracking-wide font-plexlight`}
					>
						{DYNAMIC_CONTENT_MAPPING[count]?.description}
					</div>
					<Button
						variant='gradient'
						onClick={scrollToProducts}
						text='Take a deep dive'
					/>
				</div>
			</div>
		</div>
	);
}
