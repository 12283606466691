import React from 'react'

export default function Genre() {
    const title = `What genre of content would you like to dub today?`
    const genreData = ['YouTube', 'Animated show', 'Film/Series', 'Corporate Video', 'E-Learning Video', 'Commercial/Ads', 'Podcast/Audio content']
    return (
        <>
            <section className='xl:container mx-auto pt-6 md:pt-32 px-6 xl:px-0 pb-6 md:pb-32 '>
                <p className="font-manropebold pb-6 md:pb-20 text-xl font-semibold md:text-4xl lg:text-7xl bg-gradient-to-r whitespace-pre-line	leading-tight 
                text-center from-white to-white bg-clip-text text-transparent"
                    style={{ backgroundImage: 'linear-gradient(182deg, #FFFFFF 13.26%, rgba(255, 255, 255, 0) 142.96%)' }}>
                    {title}
                </p>

                <div className='flex flex-wrap  item-center justify-center gap-3 md:gap-6'>
                    {genreData.map((item)=>{
                        return  <div className='border border-white py-2 md:py-4 px-3 md:px-12 backdrop-blur-3xl'
                        style={{ background: 'linear-gradient(230.32deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.1) 100%' }}>

                      
                        <p className=' text-xs md:text-xl text-white text-center font-plexmedium whitespace-pre-line'>{item}</p>


                    </div>
                    })}
                   


                </div>
            </section>
        </>
    )
}