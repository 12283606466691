import React from "react";
import HeroBanner from "../../../assets/images/product/iBharath/secOne.png";
import SectionBanner from "../../../assets/images/product/iBharath/iChatGPT.png";
import DescBanner from "../../../assets/images/product/iBharath/iIndiaChatDescr.png";
import FeatureOne from "../../../assets/images/product/iBharath/iIndia_feat1.svg";
import FeatureTwo from "../../../assets/images/product/iBharath/iIndia_feat2.svg";
import FeatureThree from "../../../assets/images/product/iBharath/iIndia_feat3.svg";
import FeatureFour from "../../../assets/images/product/iBharath/iIndia_feat4.svg";
import FeatureFive from "../../../assets/images/product/iBharath/iIndia_feat5.svg";
import Banner from "../sections/Banner";
import Hero from "../sections/Hero";
import Desc from "../sections/Desc";
import Feature from "../sections/Feature";
import Faq from "../sections/FAQ";
import CarouselWrapper from "../sections/CarouselWrapper";
import Closing from "../../../components/Closing";

export default function Iindia() {
  const featureContent = [
    {
      icon: FeatureOne,
      title: `Multilingual \nServices`,
      content: `Use chatbots in 22 Indian languages for seamless customer service across diverse needs.`,
    },
    {
      icon: FeatureTwo,
      title: `Multimodal \nProficiency`,
      content:
        "Speak or type, your choice. Watch your commands come to life. Simple and efficient!",
    },
    {
      icon: FeatureThree,
      title: "Q&A Chatbot & \nLearning",
      content:
        "AI excels in dynamic Q&A, constantly learning new knowledge for updated intelligence and efficiency.",
    },
    {
      icon: FeatureFour,
      title: `User \nExperience`,
      content: `Captivating digital experiences with celebrity  avatars.`,
    },

    {
      icon: FeatureFive,
      title: `Task \nSimplification`,
      content: `Streamline your workload through indiaChatGPT's unique and advanced knowledge base.`,
    },
  ];

  const faqContent = [
    {
      question: "How does indiaChatGPT work?",
      answer:
        "indiaChatGPT utilizes advanced AI algorithms to provide conversational solutions in 22 Indian languages, making it suitable for a diverse range of applications from Knowledge services to personal assistance in Various Domains",
    },
    {
      question: "Can indiaChatGPT be customized for specific industries?",
      answer:
        "Yes, indiaChatGPT can be customized for specific industries. Being an AI language model, it has the flexibility to be tailored to meet the unique needs and terminologies of different sectors.",
    },
    {
      question: "How can indiaChatGPT enhance my business?",
      answer:
        "indiaChatGPT elevates your business by seamlessly integrating AI-driven customer service, insightful data analysis, and personalized content creation, enhancing efficiency and fostering innovation.",
    },
    // Add more FAQ items as needed
  ];

  return (
    <>
      <div className="max-w-[1800px] mx-auto">
        <Hero
          banner={HeroBanner}
          title={`Unparalleled Indian Language Chatbot Experience
\nComing Soon...`}
        />
        <Desc
          banner={DescBanner}
          content={
            "Our multilingual indiaChatGPT leverages advanced NLP to deliver knowledge assistance across multiple domains and makes the experience entertaining. This groundbreaking conversational AI assistant can engage with customers in Hindi across both voice and text. 22 regional Indian languages coming soon!"
          }
        />
        <Feature
          title={`Explore powerful capabilities of \nindiaChatGPT`}
          content={featureContent}
        />
        <Banner
          banner={SectionBanner}
          title={`Innovative applications \nacross sectors. 
Coming soon!`}
        />
        <Faq faqContent={faqContent} />

        <CarouselWrapper productName="indiaChatGPT" />
        <Closing
          text="Let’s Start"
          subText="Prompting Your Imagination"
          ctaText="Start your free trial now!"
        />
      </div>
    </>
  );
}
