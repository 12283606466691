import React from 'react'
import FeatureItem from '../components/FeatureItem'

export default function Feature({ title, content }) {
    return (
        <>
            <section className={`mx-auto pt-10 px-6 md:px-6 lg:pt-32 md:pb-32 bg-crossline ${content?.length === 4 ? 'lg:w-4/6': 'xl:container'} ` } >
                <p className="productFeatTitle pb-4 mb:pb-20 text-xl  font-semibold   font-manropebold bg-gradient-to-r whitespace-pre-line	leading-tight text-center from-white to-white bg-clip-text text-transparent"
                    style={{ backgroundImage: 'linear-gradient(182deg, #FFFFFF 13.26%, rgba(255, 255, 255, 0) 142.96%)' }}>
                    {title}
                </p>
               
                <div className='flex flex-wrap lg:pt-[80px] item-center justify-between md:justify-center  md:gap-6 w-full'>

                    {content.map((item) => {
                        return (
                            <FeatureItem icon={item.icon} title={item.title} content={item.content} isThreeCol={content?.length !== 4}/>
                        )
                    })}

                </div>
            </section>
        </>
    )
}