import React, { useRef } from "react";
import Hero from "./sections/Hero";
import Products from "./sections/Products";
import About from "./sections/About";
import Blog from "./sections/Blog";
import News from "./sections/News";
import Closing from "../../components/Closing";
import BgSecImage from "../../assets/images/bgMultiOne.png";
import BgSecImage2 from "../../assets/images/bgMultiTwo.png";


export default function Home() {
	const productsRef = useRef(null);

	const scrollToProducts = () => {


		if (productsRef && productsRef.current) {
			console.log('productsRef', productsRef.current)
			productsRef.current.scrollIntoView({ behavior: 'smooth' });
		}
	  };

	return (
		<>
		<section className="">

			<Hero  scrollToProducts={scrollToProducts}/>
			<div className="lg:px-10 bg-none md:bg-cover  max-w-[1800px] mx-auto" style={{
				backgroundImage: `url(${BgSecImage})`,
				backgroundPosition: '0 41%'

			}} ref={productsRef}>
				<Products  />
				<About />
			</div>
		<div  className="multiSectionBottom max-w-[1800px] mx-auto bg-no-repeat " style={{
				backgroundImage: `url(${BgSecImage2})`,
				backgroundSize: "cover",
				backgroundPosition: '0 5%'

			}}>
		<Blog />
			<News />
		</div>
			<Closing
				text='Be inspired'
				subText='Your ideas, our tools.'
				ctaText='Start your free trial now!'
			/>
		</section>
		</>
	);
}