import React from 'react'
import HeroBanner from '../../../assets/images/product/iMusic/iMusicHero.mp4'
import SectionBannerVid from '../../../assets/images/product/iMusic/iMusicgif.mp4'
import DescBanner from '../../../assets/images/product/iMusic/iMusicDesc.png'
import FeatureOne  from '../../../assets/images/product/iMusic/iMusic_feat1.svg'
import FeatureTwo  from '../../../assets/images/product/iMusic/iMusic_feat2.svg'
import FeatureThree  from '../../../assets/images/product/iMusic/iMusic_feat3.svg'
import FeatureFour  from '../../../assets/images/product/iMusic/iMusic_feat4.svg'
import HfeatureOne  from '../../../assets/images/product/iMusic/iMusic_hfeat1.svg'
import HfeatureTwo  from '../../../assets/images/product/iMusic/iMusic_hfeat2.svg'
import HfeatureThree  from '../../../assets/images/product/iMusic/iMusic_hfeat3.svg'
import Banner from '../sections/Banner';
import Hero from '../sections/Hero';
import Desc from '../sections/Desc';
import Feature from '../sections/Feature';
import Faq from '../sections/FAQ';
import CarouselWrapper from '../sections/CarouselWrapper';
import Closing from '../../../components/Closing';
import HorizontalFeature from '../sections/HorizontalFeatures'

export default function Imusic() {
    const featureContent = [
        {
            "icon": FeatureOne,
            "title": `LIVE Concerts`,
            "content": `Experience immersive concerts featuring your beloved artist’s digital twin in the Metaverse`
        },
        {
            "icon": FeatureTwo,
            "title":"Generative Jugal \nBandis",
            "content": "Experience distinctive musical fusions with AI, seamlessly merging with different eras."
        },
        {
            "icon": FeatureThree,
            "title":`Personalized Music \nCreation`,
            "content": "Design unique songs or jingles that resonate with your brand, all crafted by AI."
        },
        {
            "icon": FeatureFour,
            "title":`Celebrity Voice \nCloning`,
            "content": "Bring back voices of your favourite Bollywood artist, add a lively beat to their legacy."
        },
        
    ]

    const HorizontalFContent = [
        {
            "icon": HfeatureOne,
            "title": `Multiple
            Languages`,
            "content": `Clone your voice from one language and generate speech in another.`
        },
        {
            "icon": HfeatureTwo,
            "title":`Works on \nShort Samples`,
            "content": "Prompt short audio samples, generating concise audio based on text prompts & lip movements."
        },
        {
            "icon": HfeatureThree,
            "title":`Instant \nResults`,
            "content": "Our high quality output is perfect for Podcasts, Marketing contents, Education, Audio books etc."
        }
        
    ]
    const faqContent = [
        { question: 'What is iMusic, and how does it operate?', 
        answer: `Our advanced AI technology Creates Exquisite music from well-known voices and legendary playback artist. Making it easy for you to enjoy, create, and share music across consumers, devices & platforms.` },
        { question: 'Can iMusic replicate the style of a particular artist?', 
        answer: `Yes, AI music can imitate a specific artist's style, capturing elements like genre, rhythm, and composition techniques.` },
        { question: 'How can I distribute the music I create?', 
        answer: `Get in touch with info@immerso@gmail.com to explore distribution opportunity.` },

        // Add more FAQ items as needed
    ];

    return (
        <>
              <div className='max-w-[1800px] mx-auto'>

            <Hero isVideo={true}  banner={HeroBanner} title={`Where Legends Never Fade`} />
            <Desc banner={DescBanner} content={`Meet iMusic, the groundbreaking innovation that brings the iconic music legends to life with new creations using proprietary state-of-the art AI technologies. Experience new songs, upcoming albums, and LIVE digital concerts like never before.`}/>

            <Feature title="Experience Music Legends Like Never Before." content={featureContent}/>
            <HorizontalFeature title={`Voice Cloning in an Instant.`} desc={`Quickly clone your voice with a short audio recording, allowing you to generate speech in multiple languages along with lipsync.`}
             content={HorizontalFContent}/>
            <Banner bannerVideo={SectionBannerVid} title={`The Magic of Music Icons \nNew songs coming soon....`} />
            <Faq faqContent={faqContent}/>

            <CarouselWrapper productName='iMusic'/>
            <Closing
				text='Enjoy Musical Experience'
				subText='Set Free Your Creativity'
				ctaText='Start your free trial now!'
			/>
            </div>
        </>
    )
}
