import React from "react";

const Privacy = () => {
  return (
    <section className="w-full h-full pt-[5%]">
      <div className="w-full h-full mx-5 sm:mx-auto container py-20 flex flex-col gap-y-10">
        <h1 className="text-5xl text-white">Privacy Policy</h1>
        <p className="text-xl italic font-plexlight text-white">
          Effective as of May 20, 2024
        </p>
        <div className="text-white text-lg flex flex-col gap-y-8">
          <p className="font-plexlight">
            This Privacy Policy describes how Immerso.ai ("Immerso.ai," "we",
            "us" or "our") processes personal information that we collect
            through our digital or online properties or services that link to
            this Privacy Policy (including as applicable, our website, mobile
            application, social media pages, marketing activities, live events,
            and other activities described in this Privacy Policy (collectively,
            the "Service")). Immerso.ai may provide additional or supplemental
            privacy policies to individuals for specific products or services
            that we offer at the time we collect personal information.
          </p>
          <p className="font-plexlight">
            Immerso.ai provides a set of tools and services for creating,
            editing, and generating content. This Privacy Policy does not apply
            to information that we process on behalf of our enterprise customers
            (such as businesses and other organizations) while providing the
            ImmersoAi platform and services to them. Our use of information that
            we process on behalf of our business customers may be governed by
            our agreements with such customers. If you have concerns regarding
            the personal data that we process on behalf of a business customer,
            please direct your concerns to that enterprise customer.
          </p>
          <h5 className="text-3xl">Index</h5>
          <ul className="list-disc font-plexlight ml-5">
            <li>Personal information we collect</li>
            <li>How we use your personal information</li>
            <li>How we share your personal information</li>
            <li>Your choices</li>
            <li>Other sites and services</li>
            <li>Security</li>
            <li>International data transfer</li>
            <li>Children</li>
            <li>Changes to this Privacy Policy</li>
            <li>How to contact us</li>
          </ul>
          <h4 className="text-3xl">Personal information we collect</h4>
          <p className="font-plexlight">
            <span className="font-manropebold">
              Information you provide to us.
            </span>{" "}
            Personal information you may provide to us through the Service or
            otherwise includes:
          </p>
          <p className="font-plexlight">
            <span className="font-manropebold">Contact data,</span> such as your
            first and last name, email address, billing and mailing addresses,
            professional title and company name, and phone number.
          </p>
          <p className="font-plexlight">
            <span className="font-manropebold">Demographic data</span> such as
            your city, state, country of residence, postal code, and age.
          </p>
          <p className="font-plexlight">
            <span className="font-manropebold">Profile data,</span> such as the
            username and password that you may set to establish an online
            account on the Service, photograph or picture, preferences, and any
            other information that you add to your account profile.
          </p>
          <p className="font-plexlight">
            <span className="font-manropebold">Communications data</span> based
            on our exchanges with you, including when you contact us through the
            Service, social media, or otherwise.
          </p>
          <p className="font-plexlight">
            <span className="font-manropebold">Transactional data, </span> such
            as information relating to or needed to complete your orders on or
            through the Service, including order numbers and transaction
            history.
          </p>
          <p className="font-plexlight">
            <span className="font-manropebold">Marketing data,</span> such as
            your preferences for receiving our marketing communications and
            details about your engagement with them.
          </p>
          <p className="font-plexlight">
            <span className="font-manropebold">
              User-generated content data,
            </span>{" "}
            such as photos, images, music, videos, comments, questions,
            messages, works of authorship, and other content or information that
            you generate, transmit, submit to us as part of a contest or live
            event, or otherwise make available on the Service, as well as
            associated metadata. Metadata includes information on how, when,
            where, and by whom a piece of content was collected and how that
            content has been formatted or edited. Metadata also includes
            information that users can add or can have added to their content,
            such as keywords, geographical or location information, and other
            similar data. If you use certain of our features, such as our
            Portrait Generator and Voice Cloning features, we and our service
            providers may also collect scans of faces or voiceprints from
            user-generated content data.
          </p>
          <p className="font-plexlight">
            <span className="font-manropebold">Other data</span> not
            specifically listed here, which we will use as described in this
            Privacy Policy or as otherwise disclosed at the time of collection.
          </p>
          <p className="font-plexlight">
            <span className="font-manropebold">Automatic data collection.</span>{" "}
            nWe, our service providers, and our business partners may
            automatically log information about you, your computer or mobile
            device, and your interaction over time with the Service, our
            communications, and other online services, such as:
          </p>
          <p className="font-plexlight">
            <span className="font-manropebold">Device data,</span> such as your
            computer or mobile device's operating system type and version,
            manufacturer and model, browser type, screen resolution, RAM and
            disk size, CPU usage, device type (e.g., phone, tablet), IP address,
            unique identifiers (including identifiers used for advertising
            purposes), language settings, mobile device carrier, radio/network
            information (e.g., Wi-Fi, LTE, 3G), and general location information
            such as city, state, or geographic area.
          </p>
          <p className="font-plexlight">
            <span className="font-manropebold">Online activity data,</span> such
            as pages or screens you viewed, how long you spent on a page or
            screen, the website you visited before browsing the Service,
            navigation paths between pages or screens, information about your
            activity on a page or screen, access times and duration of access,
            and whether you have opened our emails or clicked links within them.
            .
          </p>
          <p className="font-plexlight">
            <span className="font-manropebold">
              Communication interaction data,
            </span>{" "}
            such as your interactions with our email, text, or other
            communications (e.g., whether you open and/or forward emails) — we
            may do this through the use of pixel tags (which are also known as
            clear GIFs), which may be embedded invisibly in our emails.
          </p>
          <p className="font-plexlight">
            <span className="font-manropebold">Cookies.</span> Some of our
            automatic data collection is facilitated by cookies and similar
            technologies. We will also store a record of your preferences in
            respect of the use of these technologies in connection with the
            Service.
          </p>
          <p className="font-plexlight">
            <span className="font-manropebold">
              Cookies and similar technologies.
            </span>{" "}
            Some of the automatic collection described above is facilitated by
            the following technologies:
          </p>
          <p className="font-plexlight">
            <span className="font-manropebold">Cookies</span> are small text
            files that websites store on user devices and that allow web servers
            to record users' web browsing activities and remember their
            submissions, preferences, and login status as they navigate a site.
            Cookies used on our sites include both "session cookies" that are
            deleted when a session ends, "persistent cookies" that remain
            longer, "first party" cookies that we place, and "third party"
            cookies that our third-party business partners and service providers
            place.
          </p>
          <p className="font-plexlight">
            <span className="font-manropebold">
              Local storage technologies,
            </span>{" "}
            like HTML5, provide cookie-equivalent functionality but can store
            larger amounts of data on your device outside of your browser in
            connection with specific applications.
          </p>
          <p className="font-plexlight">
            <span className="font-manropebold">Web beacons,</span> also known as
            pixel tags or clear GIFs, are used to demonstrate that a webpage or
            email was accessed or opened, or that certain content was viewed or
            clicked.
          </p>
          <p className="font-plexlight">
            <span className="font-manropebold">Data about others.</span> We may
            offer features that help users invite their friends or contacts to
            use the Service, and we may collect contact details about these
            invitees so we can deliver their invitations. Please do not refer
            someone to us or share their contact details with us unless you have
            their permission to do so.
          </p>
          <h4 className="text-3xl">How we use your personal information</h4>
          <p className="font-plexlight">
            We may use your personal information for the following purposes or
            as otherwise described at the time of collection:
          </p>
          <p className="font-plexlight">
            <span className="font-manropebold">
              Service delivery and operations.
            </span>{" "}
            We may use your personal information to:
          </p>
          <p className="font-plexlight">
            Personalize the service, including remembering the devices from
            which you have previously logged in and remembering your selections
            and preferences as you navigate the Service;
          </p>
          <p className="font-plexlight">
            Establish and maintain your user profile on the Service;
          </p>
          <p className="font-plexlight">
            Facilitate your invitations to friends whom you want to invite to
            join the Service;
          </p>
          <p className="font-plexlight">
            Enable security features of the Service, such as by sending you
            security codes via email and remembering devices from which you have
            previously logged in;
          </p>
          <p className="font-plexlight">
            Communicate with you about the Service, including by sending
            Service-related announcements, updates, security alerts, and support
            and administrative messages;
          </p>
          <p className="font-plexlight">
            Communicate with you about events or contests in which you
            participate;
          </p>
          <p className="font-plexlight">
            Understand your needs and interests, personalize your experience
            with the Service and our communications; and
          </p>
          <p className="font-plexlight">
            Provide support for the Service, and respond to your requests,
            questions, and feedback.
          </p>
          <p className="font-plexlight">
            <span className="font-manropebold">Product improvement.</span> As
            part of our efforts to improve the Service and Immerso.ai's products
            and services, we may create aggregated, de-identified, and/or
            anonymized data from the personal information we collect. We make
            personal information into de-identified or anonymized data by
            removing information that makes the data personally identifiable to
            you. We may use this aggregated, de-identified, or otherwise
            anonymized data and share it with third parties for our lawful
            business purposes, including to analyze and improve the Service and
            promote our business, and will not attempt to reidentify any such
            data.
          </p>
          <p className="font-plexlight">
            <span className="font-manropebold">Marketing.</span> We and our
            service providers may collect and use your personal information for
            marketing and advertising purposes:
          </p>
          <p className="font-plexlight">
            <span className="font-manropebold">Direct marketing. </span> We may
            send you direct marketing communications and may personalize these
            messages based on your needs and interests. You may opt out of our
            marketing communications as described in the Opt-out of Marketing
            section below.
          </p>
          <p className="font-plexlight">
            <span className="font-manropebold">
              Events, promotions, and contests.
            </span>{" "}
            WWe may use your personal information to:
          </p>
          <p className="font-plexlight">Administer promotions and contests;</p>
          <p className="font-plexlight">
            Communicate with you about promotions or contests in which you
            participate;
          </p>
          <p className="font-plexlight">
            Contact or market to you after collecting your personal information
            at an event.
          </p>
          <p className="font-plexlight">
            <span className="font-manropebold">
              Service improvement and analytics.{" "}
            </span>{" "}
            We may use your personal information to analyze your usage of the
            Service, improve the Service, improve the rest of our business, and
            help us understand user activity on the Service, including which
            pages are most and least visited and how visitors move around the
            Service, as well as user interactions with our emails, and to
            develop new products and services.
          </p>
          <p className="font-plexlight">
            <span className="font-manropebold">Compliance and protection.</span>{" "}
            We may use your personal information to:
          </p>
          <p className="font-plexlight">
            Comply with applicable laws, lawful requests, and legal processes,
            such as to respond to subpoenas, investigations, or requests from
            government authorities;
          </p>
          <p className="font-plexlight">
            Protect our, your, or others' rights, privacy, safety, or property
            (including by making and defending legal claims);
          </p>
          <p className="font-plexlight">
            Audit our internal processes for compliance with legal and
            contractual requirements or our internal policies;
          </p>
          <p className="font-plexlight">
            Enforce the terms and conditions that govern the Service; and
          </p>
          <p className="font-plexlight">
            Prevent, identify, investigate, and deter fraudulent, harmful,
            unauthorized, unethical, or illegal activity, including cyberattacks
            and identity theft.
          </p>
          <p className="font-plexlight">
            <span className="font-manropebold">With your consent.</span> In some
            cases, we may specifically ask for your consent to collect, use, or
            share your personal information, such as when required by law.
          </p>
          <p className="font-plexlight">
            <span className="font-manropebold">
              Cookies and similar technologies.
            </span>{" "}
            In addition to the other uses included in this section, we may use
            the Cookies and similar technologies described above for the
            following purposes:
          </p>
          <p className="font-plexlight">
            <span className="font-manropebold">Technical operation.</span> To
            allow the technical operation of the Service, such as by remembering
            your selections and preferences as you navigate the site, and
            whether you are logged in when you visit password-protected areas of
            the Service.
          </p>
          <p className="font-plexlight">
            <span className="font-manropebold">Functionality. </span> To enhance
            the performance and functionality of the Service, such as by
            remembering the devices from which you have previously logged in.
          </p>
          <p className="font-plexlight">
            <span className="font-manropebold">Analytics.</span> To help us
            understand user activity on the Service, including which pages are
            most and least visited and how visitors move around the Service, as
            well as user interactions with our emails.
          </p>
          <p className="font-plexlight">
            <span className="font-manropebold">
              Interest-based advertising.
            </span>{" "}
            We, our service providers, and our third-party advertising partners
            may use first-party or third-party cookies and other cookies and
            other similar technologies, including those placed by third parties,
            to deliver advertisements, content, or features that are relevant to
            your interests based on your browsing history and otherwise on the
            Service and other online services. To learn more about your choices
            for limiting interest-based advertising, see the Advertising Choices
            section below.
          </p>
          <h4 className="text-3xl">How we share your personal information</h4>
          <p className="font-plexlight">
            We may share your personal information with the following parties
            and as otherwise described in this Privacy Policy, in other
            applicable notices, or at the time of collection.
          </p>
          <p className="font-plexlight">
            <span className="font-manropebold">Service providers.</span> Third
            parties that provide services on our behalf or help us operate the
            Service or our business (such as hosting, information technology,
            customer support, email delivery, marketing, consumer research, and
            website analytics).
          </p>
          <p className="font-plexlight">
            <span className="font-manropebold">Payment processors.</span> Any
            payment card information you use to purchase the Service is
            collected and processed directly by our payment processors, such as
            Stripe. Stripe may use your payment data by its privacy policy:
            https://stripe.com/privacy.
          </p>
          <p className="font-plexlight">
            <span className="font-manropebold">
              Third parties designated by you.
            </span>{" "}
            We may share your personal information with third parties where you
            have instructed us or provided your consent to do so. For example,
            we may share your personal information with third-party advertisers
            with whom we are collaborating to offer you additional services such
            as contests and promotions. We will share personal information that
            is needed for these other companies to provide the services that you
            have requested.
          </p>
          <p className="font-plexlight">
            <span className="font-manropebold">
              Business and marketing partners.
            </span>{" "}
            Third parties with whom we co-sponsor events or promotions, with
            whom we jointly offer products or services, or whose products or
            services may be of interest to you.
          </p>
          <p className="font-plexlight">
            <span className="font-manropebold">Professional advisors.</span>{" "}
            Professional advisors, such as lawyers, auditors, bankers, and
            insurers, where necessary in the course of the professional services
            that they render to us.
          </p>
          <p className="font-plexlight">
            <span className="font-manropebold">Authorities and others.</span>{" "}
            Law enforcement, government authorities, and private parties, as we
            believe in good faith are necessary or appropriate for the
            Compliance and protection purposes described above.
          </p>
          <p className="font-plexlight">
            <span className="font-manropebold">Business transferees.</span> We
            may disclose personal information in the context of actual or
            prospective business transactions (e.g., investments in Immerso.ai,
            financing of Immerso.ai, public stock offerings, or the sale,
            transfer, or merger of all or part of our business, assets, or
            shares), for example, we may need to share certain personal
            information with prospective counterparties and their advisers. We
            may also disclose your personal information to an acquirer,
            successor, or assignee of Immerso.ai as part of any merger,
            acquisition, sale of assets, or similar transaction, and/or in the
            event of an insolvency, bankruptcy, or receivership in which
            personal information is transferred to one or more third parties as
            one of our business assets.
          </p>
          <p className="font-plexlight">
            <span className="font-manropebold">
              Other users and the public.
            </span>{" "}
            Your profile and other user-generated content data (except for
            messages) may be visible to other users of the Service and the
            public. For example, other users of the Service or the public may
            have access to your information if you choose to make your profile
            or other personal information available to them through the Service,
            such as when you invite collaborators, provide comments, reviews, or
            share other content. This information can be seen, collected, and
            used by others, including being cached, copied, screen captured, or
            stored elsewhere by others (e.g., search engines), and we are not
            responsible for any such use of this information.
          </p>
          <p className="font-plexlight">
            We make commercially reasonable efforts to verify that the parties
            with whom our mobile application shares personal information provide
            a level of protection of personal information consistent with the
            practices described in this Privacy Policy, except that all such
            parties described above other than service providers and affiliates
            may, to the extent permitted by law, use personal information as
            described in their privacy policies.
          </p>
          <h4 className="text-3xl">Your choices</h4>
          <p className="font-plexlight">
            <span className="font-manropebold">
              Access or update your information.
            </span>{" "}
            If you have registered for an account with us through the Service,
            you may review and update certain account information by logging
            into the account.
          </p>
          <p className="font-plexlight">
            <span className="font-manropebold">Opt-out of communications.</span>{" "}
            You may opt out of marketing-related emails by following the opt-out
            or unsubscribe instructions at the bottom of the email or by
            contacting us. Please note that if you choose to opt out of
            marketing-related emails, you may continue to receive
            service-related and other non-marketing emails.
          </p>
          <p className="font-plexlight">
            <span className="font-manropebold">Cookies.</span> Most browsers let
            you remove or reject cookies. To do this, follow the instructions in
            your browser settings. Many browsers accept cookies by default until
            you change your settings. Please note that if you set your browser
            to disable cookies, the Service may not work properly. For more
            information about cookies, including how to see what cookies have
            been set on your browser and how to manage and delete them, visit
            www.allaboutcookies.org. You can also configure your device to
            prevent images from loading to prevent web beacons from functioning.
          </p>
          <p className="font-plexlight">
            <span className="font-manropebold">
              Blocking images/clear gifs.
            </span>{" "}
            Most browsers and devices allow you to configure your device to
            prevent images from loading. To do this, follow the instructions in
            your particular browser or device settings.
          </p>
          <p className="font-plexlight">
            <span className="font-manropebold">Privacy settings.</span> We make
            available certain privacy settings on the Service, including
            allowing you to make assets like your files, images, audio tracks,
            and projects sharable versus private.
          </p>
          <p className="font-plexlight">
            We cannot offer any assurances as to whether the companies we work
            with participate in the opt-out programs described above.
          </p>
          <p className="font-plexlight">
            <span className="font-manropebold">Do Not Track. </span> Some
            Internet browsers may be configured to send "Do Not Track" signals
            to the online services that you visit. We currently do not respond
            to "Do Not Track" signals. To find out more about "Do Not Track,"
            please visit www.allaboutdnt.com.
          </p>
          <p className="font-plexlight">
            <span className="font-manropebold">
              Declining to provide information.{" "}
            </span>{" "}
            We need to collect personal information to provide certain services.
            If you do not provide the information we identify as required or
            mandatory, we may not be able to provide those services.
          </p>
          <p className="font-plexlight">
            <span className="font-manropebold">
              Linked third-party platforms.{" "}
            </span>{" "}
            If you choose to connect to the Service through a third-party
            platform like Gmail, you may be able to use your settings in your
            account with that platform to limit the information we receive from
            it. If you revoke our ability to access information from a
            third-party platform, that choice will not apply to information that
            we have already received from that third party.
          </p>
          <p className="font-plexlight">
            <span className="font-manropebold">
              Delete your content or close your account.
            </span>{" "}
            You can choose to delete your account, which deletes all of your
            assets tied to that account. If you wish to request to close your
            account, please contact us or request that we delete your account
            from within the app.
          </p>

          <h4 className="text-3xl">Other sites and services</h4>
          <p className="font-plexlight">
            The Service may contain links to websites, mobile applications, and
            other online services operated by third parties. In addition, our
            content may be integrated into web pages or other online services
            that are not associated with us. These links and integrations are
            not an endorsement of or representation that we are affiliated with,
            any third party. We do not control websites, mobile applications, or
            online services operated by third parties, and we are not
            responsible for their actions. We encourage you to read the privacy
            policies of the other websites, mobile applications, and online
            services you use.
          </p>
          <h4 className="text-3xl">Security</h4>
          <p className="font-plexlight">
            {" "}
            Some We employ several technical, organizational, and physical
            safeguards designed to protect the personal information we collect.
            However, security risk is inherent in all internet and information
            technologies, and we cannot guarantee the security of your personal
            information.
          </p>
          <h4 className="text-3xl">Data Retention</h4>
          <p className="font-plexlight">
            {" "}
            We will retain your information for as long as necessary to provide
            the Service and for the other purposes set out in this Policy. In
            determining how long to retain information, we consider the amount,
            nature, and sensitivity of the information, the potential risk of
            harm from unauthorized use or disclosure of the information, the
            purposes for which we process the information, applicable legal
            requirements, and our legitimate interests. For example, we will
            retain face scans and voiceprints for no longer than the earlier
            date the initial purpose for collecting the data is satisfied or
            within three years of your last interaction with us.
          </p>
          <h4 className="text-3xl">International data transfer</h4>
          <p className="font-plexlight">
            We are headquartered in the United States and may use service
            providers that operate in other countries. Your personal information
            may be transferred to the United States or other locations where
            privacy laws may not be as protective as those in your state,
            province, or country.
          </p>
          <h4 className="text-3xl">Children</h4>
          <p className="font-plexlight">
            The Service is not intended for use by anyone under 13 years of age.
            If you are a parent or guardian of a child from whom you believe we
            have collected personal information in a manner prohibited by law,
            please contact us. If we learn that we have collected personal
            information through the Service from a child without the consent of
            the child's parent or guardian as required by law, we will comply
            with applicable legal requirements to delete the information.
          </p>
          <h4 className="text-3xl">Changes to this Privacy Policy</h4>
          <p className="font-plexlight">
            We reserve the right to modify this Privacy Policy at any time. If
            we make material changes to this Privacy Policy, we will notify you
            by updating the date of this Privacy Policy and posting it on the
            Service or other appropriate means. Any modifications to this
            Privacy Policy will be effective upon our posting the modified
            version (or as otherwise indicated at the time of posting). In all
            cases, your use of the Service after the effective date of any
            modified Privacy Policy indicates your acknowledgement that the
            modified Privacy Policy applies to your interactions with the
            Service and our business.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Privacy;
