import React from "react";
import HeroBanner from "../../../assets/images/product/iSports/banner_video.mp4";
import SectionBanner from "../../../assets/images/product/iSports/sportsBanner.png";
import DescBanner from "../../../assets/images/product/iSports/tysonBanner.png";
import FeatureOne from "../../../assets/images/product/iSports/sports_feat_one.svg";
import FeatureTwo from "../../../assets/images/product/iSports/sports_feat_two.svg";
import FeatureThree from "../../../assets/images/product/iSports/sports_feat_three.svg";
import Banner from "../sections/Banner";
import Hero from "../sections/Hero";
import Desc from "../sections/Desc";
import Feature from "../sections/Feature";
import Faq from "../sections/FAQ";
import CarouselWrapper from "../sections/CarouselWrapper";
import Closing from "../../../components/Closing";

export default function ISports() {
  const featureContent = [
    {
      icon: FeatureOne,
      title: `Immersive 3D Sports \nExperience`,
      content: `Historic fights and sports events recreated with stunning realism.`,
    },
    {
      icon: FeatureTwo,
      title: "Be part of the \naction",
      content:
        "Enjoy the game from different perspectives, Control the action like never before.",
    },
    {
      icon: FeatureThree,
      title: `Fan \nEngagement`,
      content:
        "Connect with a community of passionate sports fans, reliving the greatest moments together.",
    },
  ];

  const faqContent = [
    {
      question: "How does iSport recreate historic sports events?",
      answer: `iSport blends Machine learning, photo real technologies, Game engines and VFX to create iconic live events. Coming soon!`,
    },
    {
      question: "Can I choose any sport or event to watch?",
      answer: `Yes, you can generally choose from a wide range of sports or events to watch.`,
    },
    {
      question: `How can I access iSport content?`,
      answer: `To access iSport content, simply sign up or log in on our website or app, choose a subscription plan, and enjoy a wide range of sports.`,
    },
    // Add more FAQ items as needed
  ];

  return (
    <>
      <div className="max-w-[1800px] mx-auto">
        <Hero
          isVideo={true}
          banner={HeroBanner}
          title={"Where AI Meets Gaming"}
        />
        <Desc
          banner={DescBanner}
          content={`With immersive technology, historic fights are brought to life in stunning detail, allowing fans to relive the excitement of these classic duels. This technology offers curated experiences for combat sports fans. Virtual goods and high-fidelity interactive experiences will further delight and reward consumers and fans.`}
        />
        <Feature
          title="Unleash Your Inner Warrior with LIVE Fight Sports."
          content={featureContent}
        />
        <Banner
          banner={SectionBanner}
          title={`Where AI Meets the \nWinning Spirit`}
        />
        <Faq faqContent={faqContent} />
        <CarouselWrapper productName="iSport" />
        <Closing
          text="Level Up your "
          subText="Rumble Experience"
          ctaText="Start your free trial now!"
        />
      </div>
    </>
  );
}
