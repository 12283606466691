import React from "react";
import HeroBanner from "../../../assets/images/product/iLanguage/iLanguage_banner.mp4";
import SectionBanner from "../../../assets/images/product/iLanguage/iLangBanner.png";
import DescBanner from "../../../assets/images/product/iLanguage/iLanguage_desc_banner.png";
import FeatureOne from "../../../assets/images/product/iLanguage/iLang_feat1.svg";
import FeatureTwo from "../../../assets/images/product/iLanguage/iLang_feat2.svg";
import FeatureThree from "../../../assets/images/product/iLanguage/iLang_feat3.svg";
import FeatureFour from "../../../assets/images/product/iLanguage/iLang_feat4.svg";
import FeatureFive from "../../../assets/images/product/iLanguage/iLang_feat5.svg";
import Banner from "../sections/Banner";
import Hero from "../sections/Hero";
import Desc from "../sections/Desc";
import Feature from "../sections/Feature";
import Faq from "../sections/FAQ";
import CarouselWrapper from "../sections/CarouselWrapper";
import Closing from "../../../components/Closing";
import Metrics from "../sections/Metrics.js";
import Genre from "../sections/Genre";

export default function Ilanguage() {
  const featureContent = [
    {
      icon: FeatureOne,
      title: `End-to-End \nAutomation`,
      content: `Empower your media production by automating the entire process, ensuring optimal efficiency.`,
    },
    {
      icon: FeatureTwo,
      title: "Real time \nCaptioning",
      content:
        "Produces precise subtitles instantly in multiple languages for your projects.",
    },
    {
      icon: FeatureThree,
      title: `Multilingual \nCapability`,
      content:
        "Speech to speech and speech to text expertise available for major Indian and international language.",
    },
    {
      icon: FeatureFour,
      title: `Preserves Speakers \nStyle`,
      content:
        "Preserves unique style of every artist, while automating the process of creating quality dubbed output.",
    },
    {
      icon: FeatureFive,
      title: `Multi-cloud, Fast & \nPrivacy compliant`,
      content:
        "Deployable across AWS, GCP, Azure etc, our model guarantees the privacy and security of your data.",
    },
  ];

  const faqContent = [
    {
      question:
        "What makes iLanguage Text-to-Speech (TTS),Speech-to-Text (STT) stand out from other services?",
      answer: `iLanguage's TTS and STT services excel with superior AI for natural voice synthesis and accurate transcription, wide multilingual support, real-time processing, easy integration, and customizable voice options.`,
    },
    {
      question:
        "Can Professional Voice Cloning be implemented with Multilingual iLanguage?",
      answer:
        "Yes. iLanguage leverages advanced natural language processing (NLP) and speech synthesis techniques to enable voice cloning in multiple languages.",
    },
    // Add more FAQ items as needed
  ];
  return (
    <>
      <div className="max-w-[1800px] mx-auto">
        <Hero
          isVideo={true}
          banner={HeroBanner}
          title={`Bridging worlds Bridging Worlds with Multilingual Expertise in Automated Dubbing & Subtitling`}
        />
        <Desc
          banner={DescBanner}
          content={`Our leading AI solution ensures effortless multilingual movie captioning and dubbing. Utilizing advanced AI models, consistently trained with large datasets, we achieve very high-level accuracy comparable to manual dubs, reducing time-to-market at significantly lower costs compared to manual dubbing or subtitling.

`}
        />
        <Metrics />
        <Genre />
        <Feature
          title="The Future of AI Dubbing 
Crafting Voices, Creating Magic"
          content={featureContent}
        />

        <Banner
          bgBanner={SectionBanner}
          title={`Words Woven with Precision. \nYour Ideas. Our AI.`}
        />
        <Faq faqContent={faqContent} />

        <CarouselWrapper productName="iLanguage" />
        <Closing
          text="Let’s Start"
          subText="Elevating Audience Engagement"
          ctaText="Start your free trial now!"
        />
      </div>
    </>
  );
}
