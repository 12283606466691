import React, { useState } from 'react'
import { getProductDetails } from './products'

export default function Products() {
    const faqData = [
        { question: 'How does indiaChatGPT work?', answer: 'iBharat ChatGPT utilizes advanced AI algorithms to provide conversational solutions in 22 Indian languages, making it suitable for a diverse range of applications from Knowledge services to personal assistance in Various Domains' },
        { question: 'Can india ChatGPT be customized for specific industries?', answer: 'Yes, indiaChatGPT can be customized for specific industries. Being an AI language model, it has the flexibility to be tailored to meet the unique needs and terminologies of different sectors.' },
        { question: 'How can indiaChatGPT enhance my business?', answer: 'indiaChatGPT elevates your business by seamlessly integrating AI-driven customer service, insightful data analysis, and personalized content creation, enhancing efficiency and fostering innovation.' },
        // Add more FAQ items as needed
    ];

    const [isOpen, setIsOpen] = useState(Array(faqData.length).fill(false));

    const toggleFAQ = (index) => {
        const newIsOpen = isOpen.map((value, i) => i === index ? !value : false);
        setIsOpen(newIsOpen);
    };

    // const capSection = ({ icon, title, content }) => {
    //     return (
    //         <div className='border border-white w-[32%] p-6	'
    //             style={{ background: 'linear-gradient(230.32deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.1) 100%' }}>

    //             <div>
    //                 <img src={icon} />
    //             </div>
    //             <p className='text-xl text-white '>{title}</p>

    //             <p className='text-base	text-white'>
    //                 {content}
    //             </p>

    //         </div>
    //     )
    // }

    const params = new URLSearchParams(new URL(window.location.href).search);
    const productName = 'iBharath';
    const productDetails = getProductDetails(productName);

    if (productDetails) {
        // Render your product page with productDetails
        console.log("Product Details:", productDetails);
    } else {
        console.log("Product not found.");
    }

    const { sec_one, sec_two, sec_three } = productDetails
    return (
        <>
            
            <section className='xl:container mx-auto py-36'>
                <div className='flex justify-between'>
                    <div className='w-2/5'>
                        <p className='text-white text-4xl font-bold	pr-8 ' >Frequently Asked <br></br>Questions</p>
                    </div>

                    <div className='w-3/5'>
                        {faqData.map((item, index) => (
                            <div key={index} className={`${index !== faqData.length - 1 ? ' border-b border-dashed border-igreen200' : ''} text-white pb-9 font-plexmedium`}>
                                <div onClick={() => toggleFAQ(index)} className='flex justify-between'>
                                    <h3 className="pt-5 pb-5 text-2xl">
                                        {item.question}
                                    </h3>
                                    <span className='flex flex-col justify-center text-3xl'>{isOpen[index] ? '-' : '+'}</span>
                                </div>
                                {isOpen[index] && <p className="text-xl">{item.answer}</p>}
                            </div>
                        ))}

                    </div>
                </div>
             

            </section>
        </>
    )
}
