import React, { useEffect, useRef, useState } from "react";
import CenterImage from "../../../assets/images/news/Gradeint.png";
import ArrowImage from "../../../assets/images/news/arrow.svg";
import axios from "axios";
import config from "../../../config";

export default function News() {
  const [news, setNews] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${config.api}/api/newses?populate=*`);
        const result = await response.data.data;
        result.sort(
          (a, b) =>
            new Date(b.attributes.publishedDate) -
            new Date(a.attributes.publishedDate)
        );

        setNews(result[0]);
      } catch (error) {
        console.log("error in Fetch data", error);
      }
    };
    fetchData();
  }, []);

  // const refNews = useRef();
  // const isVisible = useIsVisible(refNews);
  return (
    <>
      <section className="block bg-cover xl:py-28 md:pt-0 md:pb-20 py-10 md:mt-5">
        <div className="xl:container px-6  relative mx-auto h-[100%]">
          <div
            className="flex items-center justify-center h-[auto]"
            style={{
              backgroundImage: `url(${CenterImage})`,
              backgroundSize: "50% 100%",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          >
            <div className="md:p-20 py-20">
              <p className="text-white md:mt-0 lg:mt-6  xl:text-5xl lg:text-3xl md:text-2xl md:top-10 top-7 w-3/5 absolute font-manropemedium  whitespace-pre-line">{`Immerso.ai Chronicles \nStay Informed`}</p>
              <div className="flex items-center justify-center pt-1 md:pt-4 md:mb-10 lg:pt-10 lg:mb-10 xl:pt-16 xl:mb-14 md:mr-0 mr-6">
                <img
                  src={news?.attributes.img.data.attributes.url}
                  className="xl:h-[500px] lg:h-[400px] md:h-[300px] h-[140px] md:w-full lg:min-w-[50rem] md:min-w-[38rem] xl:min-w-[70rem] w-[450px] shrink md:py-6 lg:py-10"
                  alt="news_bg"
                />
              </div>
              <div
                className="absolute xl:bottom-20 lg:bottom-14 md:bottom-12 bottom-8 right-[5%] md:right-6 lg:right-8 xl:right-4 md:p-6 p-3 xl:max-w-[35rem] lg:max-w-[30rem] md:max-w-[24rem] max-w-[11rem]"
                style={{
                  WebkitBackdropFilter: "blur(60px)",
                  backdropFilter: "blur(60px)",
                  background: "#ffffff0d",
                  //   bottom: "100px",
                }}
              >
                <a
                  href={news?.attributes.url}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <p className="text-white xl:text-3xl lg:text-2xl md:text-base text-[9px] whitespace-pre-line pr-5 md:pr-20 font-manropemedium ">
                    {news?.attributes.title}
                  </p>
                  <div className="flex justify-between">
                    <div className="flex justify-center flex-col">
                      <span className="text-white font-manropelight md:text-sm lg:text-lg text-[8px]">
                        {news?.attributes.publishedDate}
                      </span>
                    </div>
                    <img
                      src={ArrowImage}
                      alt="arrow_bg"
                      className="xl:h-[3.5rem] lg:h-[3rem] md:h-[2.5rem] h-[1.5rem]"
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
