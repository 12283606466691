import React from 'react'
export default function Metrics() {
    return (<>
  <section className='bg-[#1d085d] bg-no-repeat	bg-right lg:mt-0 mt-4'>
                <div className='text-white py-6 md:py-20 flex justify-between mx-auto xl:container xl:px-0 px-10 '>
                    <div className='pr-6 md:pl-0'>
                        <p className='lg:text-8xl md:text-4xl md:text-center lg:text-start font-bold font-plexsemibold pb-2'>70%+</p>
                        <p className='lg:text-2xl md:text-xl  text-xs font-plexlight md:text-center lg:text-start'>Faster Time to Market</p>
                    </div>
                    <div className='border-x lg:px-20 md:px-10 px-4 border-white'>
                        <p  className='lg:text-8xl md:text-4xl md:text-center lg:text-start font-bold font-plexsemibold pb-2'>~90%</p>
                        <p className='lg:text-2xl md:text-xl  text-xs font-plexlight  text-center'>Accuracy</p>
                    </div>
                    <div className='pl-6 xl:pl-0'>
                        <p  className='lg:text-8xl md:text-4xl md:text-center lg:text-start font-bold font-plexsemibold pb-2'>55%+</p>
                        <p className='lg:text-2xl md:text-xl  text-xs font-plexlight md:text-center lg:text-start'>Savings on Video <br></br>Dubbing & Subtiling</p>
                    </div>
                </div>
            </section>
    </>)
}