import React, { useRef } from "react";
// import Carousel from './Carousel'
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import SliderImgOne from "../../../../assets/images/product/iBharath/tyson.png";
import SliderImgTwo from "../../../../assets/images/product/iBharath/slider_music.png";
import SliderImgThree from "../../../../assets/images/product/iBharath/slider_video.png";
import SliderImgFour from "../../../../assets/images/product/iBharath/slider_chatbot.png";
import SliderImgFive from "../../../../assets/images/product/iBharath/slider_movie.png";
import SliderImgSix from "../../../../assets/images/product/iBharath/slider_visual.png";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation} from "swiper/modules";

export default function CarouselWrapper({ productName }) {
  const swiper = useSwiper();
  console.log("swiper ", swiper);
  const swiperRef = useRef(null);
  const handlePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };

  const handleNext = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  const carouselData = [
    {
      banner: SliderImgOne,
      title: "iSport",
      content:
        "Experience sports action in stunning immersive technology. Be in the Game!",
      link: "/products/iSports",
    },
    {
      banner: SliderImgTwo,
      title: "iMusic",
      content: `Your music legend lives on. New songs, timeless nostalgia!`,
      link: "/products/iMusic",
    },
    {
      banner: SliderImgThree,
      title: "iLanguage",
      content: `Power your videos and graphics with dynamic sub-titling and dubbing tools.`,
      link: "/products/iLanguage",
    },
    {
      banner: SliderImgFour,
      title: "indiaChatGPT",
      content: `Deploy Accurate Chatbots in Your Preferred Indian Language.`,
      link: "/products/Iindia",
    },
    {
      banner: SliderImgFive,
      title: "iVideo",
      content: "Where Your Imagination Comes Alive.",
      link: "/products/iVideo",
    },
    {
      banner: SliderImgSix,
      title: "iImage",
      content: "Transform your ideas into captivating visuals instantly",
      link: "/products/iImage",
    },

    [],
  ];
  const filteredCarouselData = carouselData.filter(
    (item) => item.title !== productName
  );

  return (
    <>
      <div className="w-100 pl-4 relative dive-carousel pb-36">
        <div className="flex flex-col md:flex-row xl:pl-[13%]">
          <div
            className="flex flex-col justify-between relative w-1/3 z-10 carousel-body"
            style={{ width: "411px" }}
          >
            <div className="flex flex-col text-white w-[70%] md:w-fit">
              <p className="text-lg lg:text-4xl md:text-2xl font-bold font-manropebold">
                Dive Deeper
              </p>
              <p className="text-xs md:text-base lg:text-2xl  font-plexlight pt-4">
              Discover other AI products and solutions from Immerso.ai.
              </p>
            </div>
            <div className="flex mt-4 md:top-[-50px] mySwiperNav justify-end w-full relative">
              <div className="flex relative w-[120px]">
                <div className="swiper-button-next" onClick={handleNext}></div>
                <div className="swiper-button-prev" onClick={handlePrev}></div>
              </div>
            </div>
          </div>
          <div className="md:w-2/3 md:ml-6 xl:ml-16">
            {/* <Carousel  /> */}
            <Swiper
              slidesPerView={2}
              spaceBetween={30}
              navigation={{
                prevEl: ".swiper-button-prev",
                nextEl: ".swiper-button-next",
              }}
              modules={[Navigation]}
              className="mySwiper"
            >
              {filteredCarouselData.map((item) => {
                return (
                  <SwiperSlide>
                    <a href={item.link}>
                      <div>
                        <img src={item.banner} className="w-full " alt="banner-img"/>
                        <p className="text-white font-plexmedium font-semibold text-sm md:text-3xl pt-4">
                          {item.title}
                        </p>
                        <p className="font-plexlight md:text-base lg:text-xl text-xs text-white pt-3">
                          {item.content}
                        </p>
                      </div>
                    </a>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
}
