import React from 'react'

export default function FeatureItem({ icon, title, content, isThreeCol, horizontal }){
    console.log(icon, title, content )
    return (
        <div className={`border border-white w-[48%] ${isThreeCol ? ' lg:w-[30%]': ' lg:w-[46%]'} p-2 md:p-6 mt-3 md:mt-4  backdrop-blur-3xl`}
            style={{ background: 'linear-gradient(230.32deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.1) 100%' }}>

            <div className={`pb-5 ${horizontal ? 'md:pb-2': 'md:pb-10'} max-w-12	md:max-w-max`}>
                <img src={icon} alt='icon'/>
            </div>
            <p className='featureItemTitle text-[10px]  text-white font-plexmedium whitespace-pre-line'>{title}</p>

            <p className='featureItemContent text-[8px] pt-2 md:pt-5 pb-4	text-white font-plexlight'>
                {content}
            </p>

        </div>
    )
}
        