import React from "react";
import { ReactComponent as BgIcon } from "../../assets/svg/bgIcon.svg";

const Soon = () => {
  return (
    <section className="xl:container xl:mx-auto lg:mx-11 mx-5 md:mx-9 md:pt-[120px] pt-24  flex justify-center text-white">
      <div className="w-full h-[50vh] md:h-[70vh] flex items-center justify-center relative">
        <div className="absolute">
          <BgIcon className=" w-auto h-[100px] md:h-[150px] z-10" />
        </div>
        <div className="text-center md:text-xl text-sm z-10 border md:px-8 px-4 py-2 md:py-3 border-gray-500 rounded-md overflow-hidden filter backdrop-blur-xl backdrop-opacity-100 font-plexlight">
          <p> The Big Bang of AI. Coming soon.</p>
          <p>Stay tuned!</p>
        </div>
      </div>
    </section>
  );
};

export default Soon;
