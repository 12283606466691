import React from "react";
import HeroBanner from "../../../assets/images/product/iVideo/iVideo.mp4";
import SectionBanner from "../../../assets/images/product/iVideo/iVideo_visual.png";
import DescBannerVideo from "../../../assets/images/product/iVideo/iVideo_akshay.mp4";
import DescBanner from "../../../assets/images/product/iVideo/bannerLeft.png";
import FeatureOne from "../../../assets/images/product/iVideo/iVideo_feat1.svg";
import FeatureTwo from "../../../assets/images/product/iVideo/iVideo_feat2.svg";
import FeatureThree from "../../../assets/images/product/iVideo/iVideo_feat3.svg";
import FeatureFour from "../../../assets/images/product/iVideo/iVideo_feat4.svg";
import FeatureFive from "../../../assets/images/product/iVideo/iVideo_feat5.svg";
import Banner from "../sections/Banner";
import Hero from "../sections/Hero";
import Desc from "../sections/Desc";
import Feature from "../sections/Feature";
import Faq from "../sections/FAQ";
import CarouselWrapper from "../sections/CarouselWrapper";
import Closing from "../../../components/Closing";

export default function IVideo() {
  const featureContent = [
    {
      icon: FeatureOne,
      title: `Versatility`,
      content: `Capable of generating diverse video content across genres and styles.`,
    },
    {
      icon: FeatureTwo,
      title: "Narrative \nIntelligence",
      content:
        "Translating text to visual stories, crafting engaging video narratives.",
    },
    {
      icon: FeatureThree,
      title: `Dynamic Scene \nCreation`,
      content:
        "Design and generate vibrant, interactive, and visually compelling animations.",
    },
    {
      icon: FeatureFour,
      title: `Upscale Video \nResolution `,
      content:
        "Increase resolution up to 4x. Our AI is trained on thousands of sample videos to get the details just right.",
    },
    {
      icon: FeatureFive,
      title: `Stunning Videos.\nQuick output`,
      content:
        "Seamlessly adjust and tailor visual elements: styles, color correction, rotoscopy, and inferencing in seconds.",
    },
  ];

  const faqContent = [
    {
      question: "How does iVideo works?",
      answer: `iVideo's AI transforms videos in any style you can imagine with Text & image prompts. Our advance algorithms help generate the video you want.`,
    },
    {
      question:
        "Is there a need to download anything to utilize the iVideo AI feature by iVideo AI?",
      answer: `No, there's no need to download anything to use the iVideo feature by iVideo AI, as these services are often cloud-based and operate directly through a web browser. You can simply upload your text, image or video and utilize the AI tools online.`,
    },
    {
      question: `What will be the quality of iVideos created?`,
      answer: `The quality of iVideo AI-created videos hinges on the original content's clarity, advanced AI technology, customization options, and chosen output settings, typically resulting in high-quality video outputs.`,
    },
    // Add more FAQ items as needed
  ];
  return (
    <>
      <div className='max-w-[1800px] mx-auto'>

        <Hero
          isVideo={true}
          banner={HeroBanner}
          title={"Where Your Imagination Comes Alive"}
        />
        <Desc
          isVideo={true}
          bannerVideo={DescBannerVideo}
          banner={DescBanner}
          content={`iVideo empowers your creative journey. Envision a platform where your written ideas, imaginative visuals, and personalized videos don't merely take shape but come alive with vivid energy. iVideo equips you with the essential tools to convert your prompts into stunning imagery, hyper-real videos, and more.`}
        />
        <Feature
          title={`Concept to Creation \nChoreographing Your Stories on Screen.`}
          content={featureContent}
        />
        <Banner
          bgBanner={SectionBanner}
          title={`Transforming Ideas into \nCinematic Masterpieces with Ease.\n Coming soon!`}
        />
        <Faq faqContent={faqContent} />
        <CarouselWrapper />
        <Closing
          text="Embark on a "
          subText="Journey of Visual Artistry"
          ctaText="Start your free trial now!"
        />
      </div>
    </>
  );
}
