import React, { useRef } from "react";
import { useIsVisible } from "../../../utils/hooks/useIsVisible";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { EffectCoverflow, Pagination, Navigation } from "swiper/modules";

import BgSwiper from "../../../assets/images/blog/swiperBg.png";
import First from "../../../assets/images/blog/1st.png";
import Second from "../../../assets/images/blog/2nd.png";
import Third from "../../../assets/images/blog/3rd.png";
import Fourth from "../../../assets/images/blog/4th.png";
import Fifth from "../../../assets/images/blog/5th.png";

import FirstMob from "../../../assets/images/blog/1.png";
import SecondMob from "../../../assets/images/blog/2.png";
import ThirdMob from "../../../assets/images/blog/3.png";
import FourthMob from "../../../assets/images/blog/4.png";
import FifthMob from "../../../assets/images/blog/5.png";
import BlogCard from "../../../components/BlogCard";

export default function Blog() {
  const refBlog = useRef();
  const isVisible = useIsVisible(refBlog);
  return (
    <>
      <section
        ref={refBlog}
        className={`bg-contain blogSection transition-opacity min-h-96 lg:container mx-auto ease-in duration-700 ${
          isVisible ? "opacity-100" : "opacity-0"
        }  lg:block  w-full text-white bg-cover bg-no-repeat bg-center`}
      >
        <div
          className="blogSec_title w-full pb-6 xl:pt-14 lg:pb-0 text-center 
      text-base lg:text-4xl tracking-wider 
      text-igrey font-plexlight px-4 lg:px-20 lg:px-0 pt-4  lg:pt-22"
        >
          Artificially intelligent. Genuinely useful.
        </div>
        <div className="lg:pt-20 xl:pt-36 lg:mt-4 lg:pb-8"></div>
        <div className="relative md:pt-0 text-center">
          <div
            className={`${
              isVisible && "animate-slidein [--slidein-delay:300ms] opacity-0"
            } px-10 md:px-0 text-lg pt-10  lg:text-5xl xl:text-6xl font-manropemedium leading-normal text-transparent pb-2 md:pb-0  bg-clip-text blog-title tracking-tight`}
          >
            <div className=" blogSec_cTitle">
            Future Tech Unfolded:{" "}
            </div>
            <div className=" blogSec_cTitle">Discovering Tomorrow's Solutions Today!</div>
          </div>
          <div
            className={`${
              isVisible &&
              "blogSec_cCont animate-slidein [--slidein-delay:500ms] opacity-0"
            } px-10 md:px-16 text-xs lg:text-2xl font-plexlight 
          tracking-wide pt-6 lg:pt-16 mx-auto 
          leading:loose xl:leading-10 flex 
          justify-center justify-items-center`}
          >
            Explore the frontier of AI innovations in our AI Research Hub. Delve
            into everything from machine learning breakthroughs to the ethical
            application of AI, as we unravel the ways AI is reshaping our world.
          </div>
        </div>
    </section>

    <section className="md:py-40 py-10" style={{
      backgroundImage: `url(${BgSwiper})`,backgroundSize: "cover",height: "100%"
    }}>
      <div
          className={`max-w-[1800px] hidden md:block lg:w-[100vw] md:px-5 lg:px-24 xl:px-32 mx-auto`}
        >
          <Swiper
            effect={"coverflow"}
            grabCursor={true}
            centeredSlides={true}
            loop={true}
            slidesPerView={"3"}
            rewind={true}
            coverflowEffect={{
              rotate: 1,
              stretch: 100,
              depth: 150,
              modifier: 1.5,
              slideShadows: false,
            }}
            pagination={{ el: ".swiper-pagination", clickable: true }}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
              clickable: true,
            }}
            modules={[EffectCoverflow, Pagination, Navigation]}
            className="swiper_container w-full  flex justify-center items-center"
          >
            <SwiperSlide>
              <BlogCard
                label="The Creative Future of  Generative AI"
                image={Third}
              />
            </SwiperSlide>
            <SwiperSlide>
              <BlogCard label="Deep Learning Advancements" image={Second} />
            </SwiperSlide>
            <SwiperSlide>
              <BlogCard label="Autonomous Systems and Robotics" image={First} />
            </SwiperSlide>
            <SwiperSlide>
              <BlogCard label="AI Ethics and Fairness" image={Fourth} />
            </SwiperSlide>
            <SwiperSlide>
              <BlogCard label=' AI for Healthcare' image={Fifth} />
            </SwiperSlide>

            <div className="swiper-button-prev flex items-end  2xl:ml-5"></div>
            <div className="swiper-button-next flex items-end  2xl:mr-5"></div>
          </Swiper>
        </div>
        <div
          className={`swiperBg md:hidden md:mt-20 md:mb-20  lg:w-[100vw] mx-auto`}
        >
          <Swiper
            loop={true}
            effect={"coverflow"}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={"3"}
            coverflowEffect={{
              rotate: 50,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: true,
            }}
            pagination={{ el: ".swiper-pagination", clickable: true }}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
              clickable: true,
            }}
            modules={[EffectCoverflow, Pagination, Navigation]}
            className="blogSwiper lg:hidden"
          >
            <SwiperSlide>
              <div>
                <img src={FirstMob} alt="swiper-IMG"/>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <img src={SecondMob} alt="swiper-IMG"/>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <img src={ThirdMob} alt="swiper-IMG"/>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <img src={FourthMob} alt="swiper-IMG"/>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <img src={FifthMob} alt="swiper-IMG"/>
              </div>
            </SwiperSlide>

            <div className="swiper-button-prev"></div>
            <div className="swiper-button-next"></div>
          </Swiper>
        </div>
      </section>
    </>
  );
}
