import React, { useRef } from "react";
import { motion } from "framer-motion";
import { useIsVisible } from "../../../utils/hooks/useIsVisible";
import iBharathBol from "../../../assets/images/product/iBharathBol.png";
import iLanguage from "../../../assets/images/product/iLanguage.png";
import iSport from "../../../assets/images/product/iSports.png";
import iMusic from "../../../assets/images/product/iMusic.png";
import iImage from "../../../assets/images/product/iImage.png";
import iVideo from "../../../assets/images/product/iVideo.png";
import ImmersoCard from "../../../components/Card";
import { Typography } from "@material-tailwind/react";

export default function Products() {
  const ref2 = useRef();
  const isVisible = useIsVisible(ref2);



  
  const productData = [
    {
      name: "indiaChatGPT",
      image: iBharathBol,
      url: 'products/Iindia'

    },
    {
      name: "iLanguage",
      image: iLanguage,
      url: 'products/iLanguage'

    },
    {
      name: "iMusic",
      image: iMusic,
      url: 'products/iMusic'

    },
    {
      name: "iSport",
      image: iSport,
      url: 'products/iSport'

    },
    {
      name: "iImage",
      image: iImage,
      url: 'products/iImage'

    },
    {
      name: "iVideo",
      image: iVideo,
      url: 'products/iVideo'

    },
  ];

  const handleProfileLink = (url) => {
    window.location.href = window.location.href + url
  }

  return (
    <section
      ref={ref2}
      className={`max-h-[1200px] mx-auto productsBg bg-crossline-product transition-opacity ease-in  sm:bg-contain duration-700   text-white lg:bg-cover md:bg-cover bg-no-repeat bg-right-top bg-blend-exclusion  pt-10 lg:pt-24`}
    >
      <div className="xl:container px-4 xl:px-0 m-auto lg:pt-[100px] lg:pb-[50px] xl:pt-[100px]">
        <p
          className={`${isVisible &&
            "pr-5 animate-slidein [--slidein-delay:500ms] opacity-0"
            }  font-manropemedium md:tracking-wide tracking-wider lg:leading-[4rem] text-start lg:pb-[50px]  xl:pb-[100px]`}
        >
          <div className="text-xl lg:text-4xl">
            Discover the full spectrum of multimodal AI tools.
          </div>
          <div className="text-xl lg:text-4xl pb-5">
            Invent, immerse, and inspire your tales!
          </div>
        </p>

        <motion.div className="hidden mx-auto  flex-row flex-wrap justify-center gap-x-8 items-center pt-24">
          {productData?.map((item, index) => {
            return (
              <ImmersoCard key={index} label={item.name} image={item.image} url={item.url} isHoverable />
            );
          })}
        </motion.div>
        <div className=" flex flex-wrap pb-5 ">
          {productData?.map((item, index) => {
            return (
              <div className="cursor-pointer w-1/2 md:w-1/3 md:h-auto  p-2" key={index}>
                <div className="min-h-40 bg-[#4f6287] relative sm:flex overflow-hidden hover:scale-110 transform transition-transform duration-400 hover:z-10" onClick={() => handleProfileLink(item.url)} >
                  <img src={item.image} className="w-full h-auto max-w-[450px]" alt={item.name} />
                  <div
                    className="absolute   backdrop-blur-md bottom-0 flex font-semibold justify-center
           px-4 py-2.5 md:py-6 md:px-20 text-white md:text-3xl text-base w-full cursor-pointer"
                  //   onClick={() => handleProfileLink(url)}
                  >

                    <Typography
                      variant="h4"
                      color="white"
                      className="first-letter:text-igreen md:text-2xl text-base font-medium leading-[1] justify-center font-plexmedium"
                    >
                      {item.name}
                    </Typography>

                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

// lg:grid lg:grid-rows-2 lg:grid-cols-3 gap-4
