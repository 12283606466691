import React, { useRef } from "react";

export default function Hero({ isVideo, banner, title }) {
  const videoRef = useRef(null);

  return (
    <>
      <section className=" md:container mx-auto pt-[80px] lg:pt-[120px] flex flex-col justify-center">
        {isVideo ? (
          <video ref={videoRef} controls className="w-full" loop autoPlay muted>
            <source src={banner} type="video/mp4" />
          </video>
        ) : (
          <img src={banner} alt="product-hero" />
        )}
        <div>
          <p className="productHeroTitle text-white px-6 md:px-0  text-lg md:text-4xl content-center text-center font-plexmedium pt-10  whitespace-pre-line">
            {title}
          </p>
        </div>

        <div></div>
      </section>
    </>
  );
}
