import React, { useState, useEffect } from "react";
import {
  Navbar,
  Collapse,
  Typography,
  IconButton,
  List,
  ListItem,
} from "@material-tailwind/react";
import {
  Bars3Icon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

import contact from "../../assets/images/navigation/Contact.png";
import career from "../../assets/images/navigation/Career.png";
import news from "../../assets/images/navigation/News.png";
import Blogs from "../../assets/images/navigation/Blogs.png";
import Research from "../../assets/images/navigation/rsearch.png";
import aboutUs from "../../assets/images/navigation/About.png";
import iChatGPT from "../../assets/images/navigation/ichatgpt.png";
import iLanguage from "../../assets/images/navigation/ilanguage.png";
import iSport from "../../assets/images/navigation/isports.png";
import iMusic from "../../assets/images/navigation/imusic.png";
import iImage from "../../assets/images/navigation/iimage.png";
import iVideo from "../../assets/images/navigation/iVideo.png";
import NavListMenu from "./NavList";

import LogoMob from "../../assets/images/logo.png";
import LogoDesk from "../../assets/images/logo.png";

const navListMenuItems = [
  {
    title: "indiaChatGPT",
    navTitle: "indiaChatGPT",
    description: "Voices of India, Echoing Unity.",
    icon: iChatGPT,
    link: "/products/Iindia",
  },
  {
    title: "iImage",
    navTitle: "iImage",
    description: "Where Every Pixel Tells a Story.",
    icon: iImage,
    link: "/products/iImage",
  },
  {
    title: "iSports",
    navTitle: "iSports",
    description: "Igniting Passion, Uniting Fans.",
    icon: iSport,
    link: "/products/iSports",
  },
  {
    title: "iLanguage",
    navTitle: "iLanguage",
    description: "Bridging Cultures, Connecting Worlds.",
    icon: iLanguage,
    link: "/products/iLanguage",
  },
  {
    title: "iVideo",
    navTitle: "iVideo",
    description: "Capturing Moments, Crafting Memories.",
    icon: iVideo,
    link: "/products/iVideo",
  },
  {
    title: "iMusic",
    navTitle: "iMusic",
    icon: iMusic,
    description: "Nostalgia Comes Alive!",
    link: "/products/iMusic",
  },
];

const companyListMenuItems = [
  {
    title: "About Us",
    navTitle: "About Us",
    description: "Shaping the Future of AI.",
    icon: aboutUs,
    link: "/about",
  },
  {
    title: "Career",
    navTitle: "Career",
    description: "Build Your Future with AI Revolution.",
    icon: career,
    link: "/career",
  },
  {
    title: "News",
    navTitle: "News",
    description: "Stay Updated with the Latest AI Breakthroughs.",
    icon: news,
    link: "/news",
  },
  {
    title: "Blogs",
    navTitle: "Blogs",
    description: "Stay Updated with our Insights and Innovations.",
    icon: Blogs,
    link: "/blog",
  },
  {
    title: "Research",
    navTitle: "Research",
    description: "Pioneering AI Research.",
    icon: Research,
    link: "/research",
  },
  {
    title: "Contact Us",
    navTitle: "Contact Us",
    description: "Your AI Innovation Partner.",
    icon: contact,
    link: "/contact",
  },
];

function NavList() {
  return (
    <List className="max-w-[1200px] mt-4 mb-6 p-0 lg:mt-0 lg:mb-0 lg:flex-row lg:p-1 border-slate-100">
      <Typography as="a" href="/" variant="small" className="font-medium">
        <ListItem className="flex items-center gap-2 py-2 pr-4 text-ibodygrey font-plexmedium">
          Home
        </ListItem>
      </Typography>

      {/* <NavListMenu
				navListMenuItems={navListMenuItems}
				name='Research'
			/> */}
      <NavListMenu navListMenuItems={navListMenuItems} name="Products" />
      <NavListMenu navListMenuItems={companyListMenuItems} name="Company" />
      <Typography as="a" href="/soon" variant="small" className="font-medium">
        <ListItem className="flex items-center gap-2 py-2 pr-4 text-ibodygrey font-plexmedium">
          Pricing
        </ListItem>
      </Typography>
      <div className="immerso-gradient lg:ml-8 ml-0 font-plexbold text-white text-xs lg:flex justify-center align-items-center hidden h-[2rem] p-2">
        Beta Site
      </div>
    </List>
  );
}

export function MegaMenuWithHover() {
  const [openNav, setOpenNav] = useState(false);
  const [isScroll, setIsScroll] = useState(false);

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );

    window.addEventListener("scroll", () => {
      setIsScroll(window.scrollY >= 240 ? true : false);
    });
  }, []);

  return (
    <Navbar
      className={`max-w-screen-3xl z-20 px-4 md:px-8 border-0 rounded-none ${
        isScroll ? "bg-scroll  backdrop-blur-sm" : "bg-[#030013] backdrop-blur-sm"
      } fixed`}
    >
      <div className="flex items-center xl:container mx-auto justify-between ">
        <Typography
          as="a"
          href="/"
          variant="h6"
          className="mr-4 cursor-pointer py-1.5 lg:ml-2"
        >
          <div className=" lg:hidden">
            <img src={LogoMob} width="140px" alt="logo"/>

          </div>
          <div className="hidden lg:block  w-[260px]">
          <img src={LogoDesk} alt="logo" width="100%" />

          </div>
        </Typography>
        <div className="hidden lg:block">
          <NavList />
        </div>
  <div className="flex items-center lg:hidden">
            <div className="h-fit items-end justify-end immerso-gradient py-1 px-2 md:text-sm text-xs">
              Beta
            </div>
        <IconButton
          variant="text"
          color="blue-gray"
          className="lg:hidden"
          onClick={() => setOpenNav(!openNav)}
        >
          {openNav ? (
            <XMarkIcon className="h-6 w-6" strokeWidth={2} />
          ) : (
            <Bars3Icon className="h-6 w-6" strokeWidth={2} />
          )}
        </IconButton>
  </div>
      </div>
      <Collapse open={openNav}>
        <NavList />
      </Collapse>
    </Navbar>
  );
}
