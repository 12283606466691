import React, { useRef } from "react";
import { motion } from "framer-motion";
import { useIsVisible } from "../../../utils/hooks/useIsVisible";
import HeroBgImage from "../../../assets/images/home/bgoverlay1.svg";
import HeroMob from "../../../assets/images/home/bannerMob.png";
import HeroBgImageMob from "../../../assets/images/home/mobBgHero.png";

import DynamicHero from "./components/DynamicHero";
import Button from "../../../components/Button";

export default function Home({ scrollToProducts }) {
  const ref1 = useRef();
  const isVisible = useIsVisible(ref1);

  const handleScroll = () => {
    scrollToProducts();
  };

  return (
    <>
      <div
        className="md:hidden px-5 bg-cover"
        style={{
          backgroundImage: `url(${HeroBgImageMob})`,
        }}
      >
        <div className="pt-[100px]"></div>
        <div className="flex min-h-72 flex-col justify-center">
          <img
            class="inset-0 h-full w-full object-cover"
            src={HeroMob}
            alt="build your website"
          />
        </div>
        <p className="text-4xl font-manropebold pt-10 md:pt-0 text-white font-bold pb-2">
          The Big Bang of AI
        </p>
        <p className="text-xl text-[#AEB5BB] font-normal pb-2">
          Right here. Right now!
        </p>
        <p className="text-xs text-white font-plexmedium pt-4 leading-loose">
          Innovating at the forefront of AI technology, the company focuses on
          consumer services, creator tools, AI infrastructure and research. It
          equips Consumers and enterprises with user-friendly, cutting-edge
          tools and services, that bring creative ideas to life while crafting
          the future of AI.
        </p>
        <div className="pt-10"></div>
        <Button
          onClick={handleScroll}
          variant="gradient"
          text="Take a deep dive"
        />
        <div className="pb-10"></div>
      </div>

      <motion.section
        style={{
          backgroundImage: `url(${HeroBgImage})`,
          backgroundSize: "100% 130rem",
        }}
        ref={ref1}
        className={`max-h-[1030px] transition-opacity ease-in duration-700 ${
          isVisible ? "opacity-100" : "opacity-0"
        } hidden md:top-36 md:block md:container md:mx-auto h-screen  min-h-[65rem] w-full text-white md:bg-cover md:bg-fit bg-no-repeat bg-center`}
      >
        {/* <div className='relative'> */}

        <DynamicHero scrollToProducts={scrollToProducts} />
        {/* </div> */}
      </motion.section>
    </>
  );
}
