import React from "react";

export default function Desc({ isVideo, banner, bannerVideo, content }) {
  return (
    <>
      <section className="lg:pt-32 pt-10 xl:container flex-col md:flex-row pb-10  xl:gap-16 gap-4 px-10  flex mx-auto descProduct">
        <div className="relative lg:w-1/2">
          {bannerVideo && (
            <video
              loop
              controls
              className="absolute top-44 left-0 mx-auto right-0 w-10/12"
              autoPlay
              muted
            >
              <source src={bannerVideo} type="video/mp4" />
            </video>
          )}
          <img src={banner} alt="banner-img" />
        </div>

        <div className="pt-6 lg:pt-0  md:w-3/4 lg:w-1/2 flex flex-center align-center items-center ">
          <p className="desc-banner-content text-white font-plexlight text-xs md:text-base lg:text-2xl md:leading-10 ">
            {content}
          </p>
        </div>
      </section>
    </>
  );
}
