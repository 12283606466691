import App from "../App";
import Error from "../components/Error";
import Home from "../pages/Home";
import About from "../pages/About";
import Contact from "../pages/Contact";
import Products from "../pages/Products";
import Iindia from "../pages/Products/iIndia";
import ISports from "../pages/Products/iSports";
import IVideo from "../pages/Products/iVideo";
import Ilanguage from "../pages/Products/iLanguage";
import Imusic from "../pages/Products/iMusic";
import Iimage from "../pages/Products/iImage";
import Career from "../pages/Career";
import Soon from "../pages/Soon";
import Blog from "../pages/Blog";
import BlogOne from "../pages/Blog/Blog";
import Researches from "../pages/Research";
import ResearchEach from "../pages/Research/Research";
import News from "../pages/News";
import Privacy from "../pages/Privacy & Policy";


export const routes = [
	{
		path: "/",
		element: <App component={<Home />} />,
	},
	{
		path: "/about",
		element: <App component={<About />} />,
	},
	{
		path: "/contact",
		element: <App component={<Contact />} />,
	},
	{
		path: "/products",
		element: <App component={<Products />} />,
	},
	{
		path: "/career",
		element: <App component={<Career />} />,
	},
	{
		path: "/products/Iindia",
		element: <App component={<Iindia />} />,
	},
	{
		path: "/products/iSports",
		element: <App component={<ISports />} />,
	},

	{
		path: "/products/iVideo",
		element: <App component={<IVideo />} />,
	},
	{
		path: "/products/iLanguage",
		element: <App component={<Ilanguage />} />,
	},
	{
		path: "/products/iMusic",
		element: <App component={<Imusic />} />,
	},
		
	{
		path: "/products/iImage",
		element: <App component={<Iimage />} />,
	},
	{
		path: "*",
		element: <App component={<Error />} />,
	},
	{
		path: "/soon",
		element: <App component={<Soon />} />,
	},
	{
		path: "/blog",
		element: <App component={<Blog />} />,
	},
	{
		path: "/blog/:id",
		element: <App component={<BlogOne />} />,
	},
	{
		path: "/research",
		element: <App component={<Researches />} />,
	},
	
	{
		path: "/research/:id",
		element: <App component={<ResearchEach />} />,
	},
	{
		path: "/news",
		element: <App component={<News />} />,
	},
	{
		path: "/privacy-policy",
		element: <App component={<Privacy />} />,
	},
	
	
];
