import React, { useState } from 'react'

export default function Faq({faqContent}){
    const faqData = faqContent || [
        { question: 'How does indiaChatGPT work?', answer: 'iBharat ChatGPT utilizes advanced AI algorithms to provide conversational solutions in 22 Indian languages, making it suitable for a diverse range of applications from Knowledge services to personal assistance in Various Domains' },
        { question: 'Can indiaChatGPT be customized for specific industries?', answer: 'Yes, indiaChatGPT can be customized for specific industries. Being an AI language model, it has the flexibility to be tailored to meet the unique needs and terminologies of different sectors.' },
        { question: 'How can indiaChatGPT enhance my business?', answer: 'indiaChatGPT elevates your business by seamlessly integrating AI-driven customer service, insightful data analysis, and personalized content creation, enhancing efficiency and fostering innovation.' },
        // Add more FAQ items as needed
    ] ;

    


    const [isOpen, setIsOpen] = useState(Array(faqData.length).fill( false).map((_, index) => (index === 0 ? true : false)));

    const toggleFAQ = (index) => {
        const newIsOpen = isOpen.map((value, i) => i === index ? !value : false);
        setIsOpen(newIsOpen);
    };
    return(<>
     <div className='faqSec'>
     <section className=' xl:container mx-auto py-10 lg:py-36 px-5'>
                <div className='flex flex-col lg:flex-row justify-between'>
                    <div className='lg:w-2/5 w-full'>
                        <p className='faqTitle text-white text-base md:text-2xl	lg:text-4xl font-bold lg:pr-16 pr-8 font-manropebold ' >Frequently Asked Questions</p>
                    </div>

                    <div className='lg:w-3/5'>
                        {faqData.map((item, index) => (
                            <div key={index} className={`${index !== faqData.length - 1 ? ' border-b border-dashed border-igreen200' : ''} text-white lg:pb-9 `}>
                                <div onClick={() => toggleFAQ(index)} className='flex justify-between cursor-pointer'>
                                    <h3 className="pt-5 pb-5 text-sm md:text-base lg:text-2xl font-plexmedium">
                                        {item.question}
                                    </h3>
                                    <span className='flex flex-col justify-start text-sm md:text-base pt-5 lg:text-3xl'>{isOpen[index] ? '-' : '+'}</span>
                                </div>
                                {isOpen[index] && <p className="text-xs lg:text-xl md:text-base font-plexlight pb-4">{item.answer}</p>}
                            </div>
                        ))}

                    </div>
                </div>
       

            </section>
     </div>
     </>)
}