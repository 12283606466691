import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../config";
import { useParams } from "react-router-dom";
import VideoPlayer from "../../components/VideoPlayer";

const Paragraphs = ({ paragraph }) => {
  return (
    <>
      <div>
        {paragraph.map((para, index) => (
          <div
            className="pb-5 text-white font-plexlight text-[12px]md:text-[20px] lg:text-[24px]"
            key={index}
          >
            {para.type === "paragraph" ? (
              para.children.map((child, childIndex) => {
                if (child.type === "text") {
                  return <span key={childIndex}>{child.text}</span>;
                } else if (child.type === "link") {
                  return (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-[#00D3A9]"
                      key={childIndex}
                      href={child.url}
                    >
                      {child.children.map((linkChild, linkChildIndex) => (
                        <span key={linkChildIndex}>{linkChild.text}</span>
                      ))}
                    </a>
                  );
                }
                return null;
              })
            ) : para.type === "list" ? (
              para.format === "ordered" ? (
                <ol className="ml-8 lg:ml-10">
                  {para.children.map((listChild, olListIndex) => (
                    <li className="list-decimal" key={olListIndex}>
                      {listChild.children.map((listItem, olListItemIndex) => {
                        if (listItem.type === "text") {
                          return (
                            <span key={olListItemIndex}>{listItem.text}</span>
                          );
                        } else if (listItem.type === "link") {
                          return (
                            <a
                              key={olListItemIndex}
                              href={listItem.url}
                              className="text-[#00D3A9]"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {listItem.children.map(
                                (linkChild, linkChildIndex) => (
                                  <span key={linkChildIndex}>
                                    {linkChild.text}
                                  </span>
                                )
                              )}
                            </a>
                          );
                        }
                        return null;
                      })}
                    </li>
                  ))}
                </ol>
              ) : (
                <ul className="ml-8 lg:ml-10">
                  {para.children.map((listChild, ulListIndex) => (
                    <li key={ulListIndex} className="list-disc">
                      {listChild.children.map((listItem, ulListItemIndex) => {
                        if (listItem.type === "text") {
                          return (
                            <span key={ulListItemIndex}>{listItem.text}</span>
                          );
                        } else if (listItem.type === "link") {
                          return (
                            <a
                              key={ulListItemIndex}
                              href={listItem.url}
                              className="text-[#00D3A9]"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {listItem.children.map(
                                (linkChild, linkChildIndex) => (
                                  <span key={linkChildIndex}>
                                    {linkChild.text}
                                  </span>
                                )
                              )}
                            </a>
                          );
                        }
                        return null;
                      })}
                    </li>
                  ))}
                </ul>
              )
            ) : null}
          </div>
        ))}
      </div>
    </>
  );
};

export default function BlogOneTemplate() {
  const [data, setData] = useState();
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${config.api}/api/blogs/${id}?populate=Image,contents.contentImg`
        );
        const result = await response.data.data;
        setData(result.attributes);
      } catch (error) {
        console.log("error in Fetch data", error);
      }
    };
    fetchData();
  }, [id]);
console.log(data, "data");
  return (
    <>
      {data && (
        <div className="">
          <section className="xl:container 2xl:mx-auto xl:mx-16 lg:mx-12 md:mx-9 mx-6 pt-[120px] flex flex-col  px-6 lg:px-[100px] justify-center">
            <>
              <div className="md:mt-8 mt-0 md:pr-[140px] pb-6 md:pb-0">
                <p className="text-white text-[30px] md:text-[48px] font-semibold font-manropebold leading-[1] md:leading-[4rem]">
                  {data.Title}
                </p>
                <p className="font-plexlight  text-[12px] md:text-[20px] pt-3 pb-6 text-[#C7C5C5]">
                  {data.SubTitle}
                </p>
                <p className="font-plexlight text-[10px] md:text-[16px] pt-3 pb-6  text-[#C7C5C5]">
                  {data.author} <br></br>
                  Publication Date: {data.PublicationDate}
                </p>
                {data.credit && (
                  <p className="text-[#00D3A9] text-[10px] md:text-[16px] pb-10 ">
                    Blog Credit - {data.credit}
                  </p>
                )}
              </div>
              <div className="w-full">
                <img
                  src={data.Image.data.attributes.url}
                  alt="blog-img"
                  width="100%"
                />
              </div>
              {data.imgDesc && (
                <div>
                  <p className="text-white pt-2 md:pt-10  font-plexlight text-[12px] md:text-[16px] pb-10">
                    {data.imgDesc.split("\n").map((line, index) => (
                      <p key={index}>{line}</p>
                    ))}
                  </p>
                </div>
              )}
              {data.contents.map((item, contentIndex) => (
                <>
                  <div key={contentIndex} className="pt-10">
                    {item.mainHeader && (
                      <p className="gradient-blog-sub text-[16px] md:text-[40px] font-manropemedium md:py-[40px]">
                        {item.mainHeader}
                      </p>
                    )}
                    {item.header && (
                      <p className="text-[16px] md:text-[28px] font-plexlight py-3 text-[#00D3A9]">
                        {item.header}
                      </p>
                    )}
                    {item.paragraphs && (
                      <Paragraphs paragraph={item.paragraphs} />
                    )}
                    {item.url && (
                      <div className="flex flex-col md:flex-row py-5">
                        <div className="w-full md:w-[45%] md:h-[auto] pr-6">
                          <VideoPlayer videoId={item.url} />
                        </div>
                        <div className="w-full md:w-[55%]">
                          <p className="font-manropemedium text-[14px] md:text-[24px] pb-2 text-[#00D3A9]">
                            {item.contentMediaHeader}
                          </p>
                          <p className="font-plexlight text-[#E5E5E5] text-[10px] md:text-[16px] pb-6">
                            {item.contentMediaDesc}
                          </p>
                        </div>
                      </div>
                    )}
                    {item.contentImg.data &&(
                      <div className="pt-6 flex items-center justify-center flex-col">
                        <img src={item.contentImg.data[0].attributes.url} width='100%' alt="content-img"/>
                       {item.contentMediaHeader &&( <p className="text-white font-plexlight text-[10px] md:text-[12px] lg:text-[14px] xl:text-[16px]  xl:pt-7">{item.contentMediaHeader}</p>)}
                    </div>
                    )}
                  </div>
                </>
              ))}

              <p className="pb-10 md:pb-24  xl:pb-[150px]"></p>
            </>
          </section>
        </div>
      )}
    </>
  );
}
