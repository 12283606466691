import React from 'react'
import FeatureItem from '../components/FeatureItem'

export default function HorizontalFeature({ title, desc, content }) {
    return (
        <>
            <section className='flex flex-col lg:flex-row mx-auto pt-32 px-5 xl:px-0 pb-10 md:pb-32 bg-crossline xl:container'>
                <div className='flex flex-col lg:pr-16 lg:w-7/12 md:justify-center	'>
                <p className="lg:text-left	 pb-4 md:pb-14 text-2xl font-manropebold font-semibold md:text-6xl bg-gradient-to-r whitespace-pre-line leading-normal  from-white to-white bg-clip-text text-transparent"
                    style={{ backgroundImage: 'linear-gradient(182deg, #FFFFFF 13.26%, rgba(255, 255, 255, 0) 142.96%)' }}>
                    {title}
                </p>

                <p className="lg:text-left 	font-plexlight pb-4 lg:pb-4  text-xs md:text-xl  whitespace-pre-line leading-loose text-white "
                    >
                    {desc}
                </p>
                </div>
                
               
                <div className='flex item-center md:justify-center justify-between  gap-2  md:gap-6 h-max w-full flex-wrap md:flex-nowrap '>

                    {content.map((item) => {
                        return (
                            <FeatureItem icon={item.icon} title={item.title} content={item.content} horizontal={true}/>
                        )
                    })}

                </div>
            </section>
        </>
    )
}