import React, { useRef } from "react";
import { motion } from "framer-motion";
import { useIsVisible } from "../../../utils/hooks/useIsVisible";
import HeroBgImage from "../../../assets/images/Contact-bg.png";

export default function Home() {
	const ref1 = useRef();
	const isVisible = useIsVisible(ref1);
	return (
		<motion.section
			style={{
				backgroundImage: `url(${HeroBgImage})`,
				backgroundSize: "cover",
			}}
			ref={ref1}
			className={`transition-opacity ease-in duration-700 contact-hero ${
				isVisible ? "opacity-100" : "opacity-0"
			} h-fit  pt-[150px] md:px-0 py-32 md:py-0 md:h-[50vh] flex flex-col justify-center  xl:h-screen w-full text-white lg:bg-cover md:bg-fit bg-no-repeat bg-center`}
		>
			<div className='relative  xl:container xl:mx-auto px-6  xl:px-0  flex flex-col'>
				<div
					className={`${
						isVisible && "animate-slidein [--slidein-delay:300ms] opacity-0"
					} md:text-7xl text-2xl font-semibold leading-snug font-manropebold tracking-tight hero-title contactSec inline-block bg-clip-text text-transparent`}
				>
					We’d love
				</div>
				<div
					className={`${
						isVisible && "animate-slidein [--slidein-delay:300ms] opacity-0"
					} md:text-7xl text-2xl font-semibold leading-snug font-manropebold tracking-tight hero-title contactSec inline-block bg-clip-text text-transparent`}
				>
					to hear from you
				</div>
			</div>
		</motion.section>
	);
}
