import {
	Card,
	CardHeader,
	CardBody,
	Typography,
} from "@material-tailwind/react";
import { ReactComponent as Logo } from "../../assets/images/next.svg";

export default function BlogCard({ label, image, isHoverable }) {
	return (
		<Card
			shadow={false}
			className={`relative grid h-[34rem] w-[29rem] lg:h-[35rem] lg:w-[30rem]  xl:h-[600px] xl:w-[500px] items-end justify-center text-center rounded-none mt-6 mb-0 mx-auto hover:-translate-y-6 hover:z-1000 cursor-pointer transition-all transition delay-50 duration-300 ease-in-out bg-transparent overflow-hidden`}
		>
			<CardHeader
				floated={false}
				shadow={false}
				color='transparent'
				className='absolute inset-0 m-0 xl:  h-full w-full rounded-none bg-cover bg-top  transition-all transition delay-100 duration-300 ease-in-out'
				style={{ backgroundImage: `url(${image})` }}

			></CardHeader>
			<CardBody className='backdrop-blur-2xl  flex justify-between items-center w-[35rem] lg:h-[8rem] h-[5rem]  relative md:pl-14 lg:pl-14 '>
				<div className='w-4/5 lg:h-[5rem] h-[2rem] flex justify-start items-center align-middle'>
					<Typography
						color='white'
						className='lg:text-2xl text-xl	text-left font-plexmedium '
					>
						{label}
					</Typography>
				</div>
				<div className='w-1/5'>
					<Logo className='lg:h-[3rem] h-[2rem] top-4 lg:top-10 xl:right-2 lg:right-10 right-4' />
				</div>
			</CardBody>
		</Card>
	);
}
