import React, { useState } from "react";
import CareerBanner from "../../assets/images/career/careersBanner.png";
import BenefitImg1 from "../../assets/images/career/benefits-icon-1.png";
import BenefitImg2 from "../../assets/images/career/benefits-icon-2.png";
import BenefitImg3 from "../../assets/images/career/benefits-icon-3.png";
import Closing from "../../components/Closing";

export default function Career() {
  const careerData = [
    {
      title: `Product Marketing Manager - 
        Generative AI`,
      category: `Business`,
      content: {
        title: "5+ Years of Product Marketing Experience",
        skills: [
          "Experience managing and measuring marketing performance in various channels",
          "Product marketing experience and familiarity with generative AI or developer audiences",
          "Strong Communication and Presentation skills.",
          "Ability to meet tight deadlines with high quality work.",
          "Strong knowledge of AI & Saas Tools.",
        ],
        jd: "As a Product Marketing Manager, you will engage our customers and prospects to help them understand how our next generation AI tools and services that helps them be more productive, while also entertaining audiences building products with global intellectual properties.",
      },
    },
    // Add more FAQ items as needed
  ];

  const benefitsImages = [
    {
      img: BenefitImg1,
      title: "work-Life Balance Nurturing",
    },
    {
      img: BenefitImg2,
      title: "Competitive Compensation and Performance Rewards",
    },
    {
      img: BenefitImg3,
      title: "Health and Wellness Programs",
    },
  ];

  const [isOpen, setIsOpen] = useState(Array(careerData.length).fill(false));
  console.log("careerData", careerData);
  console.log("careerData", isOpen);
  const toggleFAQ = (index) => {
    const newIsOpen = isOpen.map((value, i) => (i === index ? !value : false));
    setIsOpen(newIsOpen);
  };

  const [section, setSection] = useState("All");

  return (
    <>
      <div className="">
        <section className="xl:container xl:mx-auto lg:mx-11 mx-5 md:mx-9 md:pt-[120px] pt-24  flex justify-center">
          <>
            <div className="md:flex hidden">
              <div className="lg:mt-20 mt-12 w-1/2">
                <p className="text-white  xl:text-6xl font-manropeextrabold lg:text-[35px] md:text-2xl lg:leading-tight xl:leading-tight">
                  Do the{" "}
                  <span className="gradient-head-main">
                    best work of your life
                  </span>
                  , which you otherwise couldn’t!
                </p>
                <p className="text-white xl:text-2xl font-plexlight lg:text-lg md:text-[13.5px]  mt-5">
                  Join the Immerso.ai family and be at the forefront of AI
                  innovation. Whether you're an engineer, researcher, or even a
                  poet, there's a place for you in our community!
                </p>
                <div className="flex overflow-hidden border rounded-full w-[90%]  xl:w-96 justify-between items-center xl:mt-20 lg:mt-6 mt-5 px-3  lg:py-1 border-gray-200 shadow-md">
                  {["All", "Engineering", "Business", "Research"].map(
                    (item, index) => (
                      <button
                        key={index}
                        className={`text-white font-medium text-sm lg:px-4 px-2 xl:py-1 py-1 my-1 ${
                          section === item
                            ? "gradient-button  rounded-full "
                            : ""
                        }`}
                        onClick={() =>
                          setSection(item === section ? null : item)
                        }
                      >
                        {item}
                      </button>
                    )
                  )}
                </div>
              </div>

              <div className="w-1/2">
                <img src={CareerBanner} alt="career-img" className="w-full"/>
              </div>
            </div>

            <div className="md:hidden flex flex-col">
              <div className="pt-10 w-full flex flex-col ">
                <p className="text-white text-[32px] font-manropeextrabold leading-tight">
                  Do the{" "}
                  <span className="gradient-head-main">
                    best work of your life
                  </span>
                  , which you otherwise couldn’t!
                </p>
                <p className="text-white font-plexlight text-sm font-thin mt-4">
                  Join the Immerso.ai family and be at the forefront of AI
                  innovation. Whether you're an engineer, researcher, or even a
                  poet, there's a place for you in our community!
                </p>
              </div>

              <div className="w-full ">
                <img src={CareerBanner} alt="career-banner"/>
              </div>

              <div className="w-full">
                <div className="flex  overflow-hidden border rounded-full w-full justify-between items-center mt-10 px-2  border-gray-200 shadow-md">
                  {["All", "Engineering", "Business", "Research"].map(
                    (item, index) => (
                      <button
                        key={index}
                        className={`text-white font-medium text-sm px-[5px]  py-0  my-2 ${
                          section === item
                            ? "gradient-button  rounded-full "
                            : ""
                        }`}
                        onClick={() =>
                          setSection(item === section ? null : item)
                        }
                      >
                        {item}
                      </button>
                    )
                  )}
                </div>
              </div>
            </div>
          </>
        </section>

        <section className="xl:container xl:mx-auto lg:mx-11 mx-5 md:mx-9 xl:my-32 my-14">
          <>
            <div className="md:flex hidden flex-col">
              {careerData.map((item, index) => (
                <div
                  key={index}
                  onClick={() => toggleFAQ(index)}
                  className={`${index === 0 ? "border-t" : ""} 
                            ${
                              isOpen[index] ? "h-max" : " h-44"
                            } border-b border-dashed border-igreen200 overflow-hidden text-white pb-2 font-plexmedium `}
                >
                  <div className="flex justify-between cursor-pointer my-8">
                    <div className="flex w-1/2">
                      <div className="w-1/2">
                        <p className="whitespace-pre-line xl:text-2xl lg:text-xl text-lg font-plexmedium">
                          {item.title}
                        </p>
                      </div>
                      <div className="w-1/2">
                        <p className=" xl:text-xl lg:text-lg text-sm font-plexlight">
                          {item.category}
                        </p>
                      </div>
                    </div>
                    <div className="w-1/2">
                      <p className=" xl:text-2xl lg:text-xl text-lg font-thin pb-2 ">
                        {item.content.title}
                      </p>
                      <p className="font-plexlight xl:text-xl lg:text-sm text-[12px]">
                        Skills -{" "}
                      </p>
                      <ul className="font-plexlight list-disc xl:text-xl lg:text-sm text-[12px] mb-10">
                        {isOpen[index] ? (
                          item.content.skills.map((list, index) => (
                            <li className="ml-6" key={index}>
                              {list}
                            </li>
                          ))
                        ) : (
                          <li className="ml-6">{item.content.skills[0]}</li>
                        )}
                      </ul>
                      <p className="xl:text-2xl lg:text-xl text-lg font-thin pb-2">
                        Job Description:
                      </p>
                      <p className="font-plexlight xl:text-xl lg:text-sm text-[12px]">
                        {item.content.jd}
                      </p>
                      <button className="xl:text-2xl lg:text-xl text-lg gradient-button px-6 py-2 mt-10">
                        Apply Now
                      </button>
                    </div>
                    <span className="flex flex-col justify-start text-3xl">
                      {isOpen[index] ? "-" : "+"}
                    </span>
                  </div>
                </div>
              ))}
            </div>

            <div className="md:hidden flex flex-col">
              {careerData.map((item, index) => (
                <div
                  key={index}
                  onClick={() => toggleFAQ(index)}
                  className={`${index === 0 ? "border-t" : ""} 
                            ${
                              isOpen[index] ? "h-max" : " h-34"
                            } border-b border-dashed border-igreen200 overflow-hidden text-white pb-1 font-plexmedium `}
                >
                  <div className="flex flex-col items-start gap-2 cursor-pointer my-5 relative">
                    <div className="flex flex-col w-full">
                      <div className="w-full flex pb-1">
                        <p className="whitespace-pre-line text-lg font-plexmedium">
                          {item.title}
                        </p>
                      </div>
                      <div className="w-full">
                        <p className="text-[12px] font-plexlight">
                          {item.category}
                        </p>
                      </div>
                    </div>
                    <div className="w-full">
                      <p className=" text-[13px] font-thin">
                        {item.content.title}
                      </p>
                      {isOpen[index] && (
                        <>
                          <p className="font-plexlight xl:text-xl lg:text-sm pt-1 text-[12px]">
                            Skills -{" "}
                          </p>

                          <ul className="font-plexlight list-disc xl:text-xl lg:text-sm text-[12px] mb-10">
                            {isOpen[index] ? (
                              item.content.skills.map((list, index) => (
                                <li className="ml-6" key={index}>
                                  {list}
                                </li>
                              ))
                            ) : (
                              <li className="ml-6">{item.content.skills[0]}</li>
                            )}
                          </ul>
                          <p className="text-lg font-thin pb-2">
                            Job Description:
                          </p>
                          <p className="font-plexlight xl:text-xl lg:text-sm text-[12px]">
                            {item.content.jd}
                          </p>
                          <button
                            className="text-sm gradient-button px-5 py-2 mt-8"
                            onClick={() => console.log("clicked")}
                          >
                            Apply Now
                          </button>
                        </>
                      )}
                    </div>
                    <span className="absolute top-0 right-0  text-2xl">
                      {isOpen[index] ? "-" : "+"}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </>
        </section>

        <section className="text-white md:pb-24 pb-12 xl:mx-auto lg:mx-11 mx-5 md:mx-9">
          <div className="container mx-auto flex flex-col">
            <h1 className="lg:text-5xl md:text-4xl text-3xl font-bold mb-8">
              Benefits
            </h1>
            <p className="font-plexlight md:leading-loose md:mb-12 mb-6 lg:text-[24px] md:text-xl text-[14px] font-thin">
              At Immerso.ai, we understand that our employees are our greatest
              asset, which is why we offer a comprehensive benefits package
              designed to support their personal and professional growth. Our
              aim is to create an inclusive, high-energy work environment where
              performance is rewarded, work-life balance is nurtured, and
              long-term well-being is looked after. From competitive financial
              rewards to flexible working options to robust health and wellness
              programs.
            </p>
            <div className="flex justify-between md:gap-9 gap-1">
              {benefitsImages.map((item, index) => (
                <div className="flex flex-col" key={index}>
                  <img
                    src={item.img}
                    alt="benefits-img"
                    className="w-full h-auto mb-4"
                  />
                  <h2 className="lg:text-2xl md:text-[16px] text-xs text-white font-thin mb-2 text-start">
                    {item.title}
                  </h2>
                </div>
              ))}
            </div>
          </div>
        </section>

        <Closing
          text="Let's Make"
          subText={`Something Awesome Together`}
          ctaText="Start your free trial now!"
        />
      </div>
    </>
  );
}
