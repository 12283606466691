import SecFourBanner from '../../assets/images/product/iBharath/secFourBanner.png'
import SecOne_ibharath from '../../assets/images/product/iBharath/secOne.png'
import SecTwo_iBharath from '../../assets/images/product/iBharath/secTwo.png'

export const productData = {
    "products": [
        {
            "name": "ibharath",
            "sec_one": {
                "img": SecOne_ibharath,
                "title": "Unparalleled Indian Language Chatbot Experience -Coming soon!"
            },
            "sec_two": {
                "img": SecTwo_iBharath,
                "content": "Our multilingual iBharatBol leverages advanced NLP to deliver knowledge assistance across multiple domains and makes the experience entertaining. This groundbreaking conversational AI assistant can engage with customers in Hindi across both voice and text. 22 regional Indian languages coming soon!"
            },
            "sec_three": {
                "title": "Explore powerful capabilities \nof iBharatBol",
                "content": [
                    {
                        "icon": "",
                        "title":"",
                        "content": ""
                    },
                    {
                        "icon": "",
                        "title":"",
                        "content": ""
                    },
                    {
                        "icon": "",
                        "title":"",
                        "content": ""
                    }
                ]
            },
            "sec_four": {
                "title": `Innovative Applications \nAcross Sectors - Coming Soon`,
                "banner": SecFourBanner,
                "backgroundImage":''
            }

        }

    ]
} 


export const getProductDetails = (productName) => {
    const product = productData.products.find(p => p?.name?.toLowerCase() === productName?.toLowerCase());
    return product || null; // Return product or null if not found
};