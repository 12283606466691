import React, { useRef } from "react";
import { useIsVisible } from "../../utils/hooks/useIsVisible";
import BgImage from "../../assets/images/closing/BgMob.png";
import BgGradient from "../../assets/images/closing/bannerBg.png";
import ichat from "../../assets/images/closing/ichat.png";
import iVideo from "../../assets/images/closing/iVideo.png";
import iMusic from "../../assets/images/closing/iMusic.png";
import iLanguage from "../../assets/images/closing/ilanguage.png";
import iSports from "../../assets/images/closing/iSports.png";
import iImage from "../../assets/images/closing/iImage.png";
import Button from "../../components/Button";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";

const ImageGrid = ({ images, translateY }) => (
  <Parallax
    translateY={translateY}
    speed={-10}
    style={{ rotate: "14deg" }}
    className="flex relative gap-3 flex-col px-3 "
  >
    {images.map((image, index) => (
      <img
        key={index}
        src={image}
        alt="bg-img"
        className="xl:w-[350px] h-auto"
      />
    ))}
  </Parallax>
);

export default function Closing({ text, subText, ctaText }) {
  const refClosing = useRef();
  const isVisible = useIsVisible(refClosing);
  const handleRedirection = () => {
    window.location.href = "/soon";
  };
  return (
    <ParallaxProvider>
      <section
        ref={refClosing}
        className={`max-h-[800px]  max-w-[1800px] mx-auto flex items-center justify-center bg-contain relative  h-[50vh]  lg:h-[70vh] xl:h-[80vh] w-screen text-white lg:bg-cover  bg-no-repeat bg-top overflow-hidden`}
      >
        <div
          className="relative md:hidden flex items-center justify-center inset-0 z-0 overflow-hidden"
          style={{
            backgroundImage: `url(${BgImage})`,
            width: "100%",
            height: "100%",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="absolute top-[32%] m-auto items-center flex flex-col justify-center text-center z-50">
            <div
              className={`${
                isVisible && "animate-slidein [--slidein-delay:300ms] opacity-0"
              } whitespace-pre-line text-base font-manropemedium leading-normal text-white bg-clip-text tracking-tight`}
            >
              {text}
            </div>
            <div
              className={`${
                isVisible && "animate-slidein [--slidein-delay:500ms] opacity-0"
              } whitespace-pre-line text-xl font-manropebold leading-normal tracking-tight bg-gradient-to-r from-iviolet to-igreen inline-block text-transparent bg-clip-text  pb-3`}
            >
              {subText}
            </div>
            <div
              className={`${
                isVisible &&
                "text-[10px] lg:text:xl animate-slidein [--slidein-delay:700ms] opacity-0"
              }  absolute mt-24 flex items-center justify-center`}
            >
              <Button
                variant="gradient"
                onClick={handleRedirection}
                text={ctaText}
              />
            </div>
          </div>
        </div>

        <div className="hidden md:flex h-screen w-screen  relative items-center justify-center overflow-hidden max-h-[800px] max-w-[1800px]">
          <div
            style={{
              backgroundImage: `url(${BgGradient})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              height: "100vh",
              width: "100vw",
              objectFit: "cover",
            }}
            className="hidden md:block relative h-screen w-screen"
          >
            <div className="hidden absolute inset-0 md:flex items-center opacity-15 justify-center mx-[-200px] overflow-hidden">
              <ImageGrid
                images={[ichat, iImage, iSports, iLanguage, iVideo, iMusic]}
                translateY={[-20, 10]}
              />

              <ImageGrid
                images={[iMusic, iLanguage, iVideo, iSports, iImage, ichat]}
                translateY={[10, -10]}
              />
              <ImageGrid
                images={[iSports, iImage, iLanguage, ichat, iMusic, iVideo]}
                translateY={[-20, 10]}
              />
              <ImageGrid
                images={[iLanguage, iMusic, iSports, iImage, ichat, iVideo]}
                translateY={[10, -10]}
              />
              <ImageGrid
                images={[iLanguage, iSports, ichat, iMusic, iVideo, iImage]}
                translateY={[-20, 10]}
              />
              <ImageGrid
                images={[iMusic, iSports, iVideo, iLanguage, iImage, ichat]}
                translateY={[10, -10]}
              />
            </div>
          </div>

          <div className="absolute hidden md:flex top-[40%] lg:top-[39%] xl:top-[40%]  m-auto items-center  flex-col justify-center text-center z-10 ">
            <div
              className={`${
                isVisible && "animate-slidein [--slidein-delay:300ms] opacity-0"
              } whitespace-pre-line text-base md:text-xl xl:text-5xl lg:text-4xl mb-1  font-manropemedium leading-normal text-white bg-clip-text tracking-tight`}
            >
              {text}
            </div>
            <div
              className={`${
                isVisible && "animate-slidein [--slidein-delay:500ms] opacity-0"
              } whitespace-pre-line text-xl xl:text-7xl lg:text-5xl md:text-3xl font-manropebold leading-normal tracking-tight bg-gradient-to-r from-iviolet to-igreen inline-block text-transparent bg-clip-text  pb-3`}
            >
              {subText}
            </div>
            <div
              className={`${
                isVisible &&
                "text-[10px] lg:text:xl animate-slidein [--slidein-delay:700ms] opacity-0"
              }  absolute mt-28  lg:mt-44 flex items-center justify-center`}
            >
              <Button
                variant="gradient"
                onClick={handleRedirection}
                text={ctaText}
              />
            </div>
          </div>
        </div>

        {/* <div className="relative top-1/3 text-center mx-auto pb-5 flex flex-col justify-center items-center" >
        <div
          className={`${
            isVisible && "animate-slidein [--slidein-delay:300ms] opacity-0"
          } whitespace-pre-line text-base md:text-5xl  font-manropemedium leading-normal text-white bg-clip-text tracking-tight`}
        >
          {text}
        </div>
        <div
          className={`${
            isVisible && "animate-slidein [--slidein-delay:500ms] opacity-0"
          } whitespace-pre-line text-xl lg:text-7xl md:text-6xl font-manropebold leading-normal tracking-tight bg-gradient-to-r from-iviolet to-igreen inline-block text-transparent bg-clip-text  pb-3`}
        >
          {subText}
        </div>
        <div
          className={`${
            isVisible &&
            "text-xs lg:text:xl animate-slidein [--slidein-delay:700ms] opacity-0"
          } `}
        >
          <Button variant="gradient" onClick={handleRedirection} text={ctaText} />
        </div>
      </div> */}
      </section>
    </ParallaxProvider>
  );
}
