import React, { useEffect, useState } from "react";
import Button from "../../components/Button";
import Closing from "../../components/Closing";
import axios from "axios";
import config from "../../config";

export default function News() {
  const [data, setData] = useState();
  const [visibleItems, setVisibleItems] = useState(6);
  const itemsPerPage = 2;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${config.api}/api/newses?populate=*`);
        const result = await response.data.data;
        result.sort(
          (a, b) =>
            new Date(b.attributes.publishedDate) -
            new Date(a.attributes.publishedDate)
        );
        setData(result);
      } catch (error) {
        console.log("error in Fetch data", error);
      }
    };
    fetchData();
  }, []);

  const loadMore = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + itemsPerPage);
  };

  return (
    <>
      {data && (
        <>
          {data.slice(0, visibleItems)?.map((item, index) => (
            <div key={index}>
              {index === 0 && (
                <section className="xl:container 2xl:mx-auto xl:mx-16 lg:mx-12 md:mx-9 mx-6 pt-[120px] flex flex-col md:flex-row justify-center">
                  <div className="md:hidden block md:w-1/2 mb-10">
                    <img
                      src={item.attributes.img.data.attributes.url}
                      alt="blog-img"
                    />
                  </div>

                  <div className="md:mt-8 mt-0 pr-0 xl:pr-[140px] md:w-1/2 pb-6 md:pb-0">
                    <p className="text-white text-[30px] md:text-[25px] lg:text-[30px] xl:text-[48px] font-semibold font-manropebold leading-[1] md:leading-8 xl:leading-[4rem]">
                      {item.attributes.title}
                    </p>
                    <p className="font-plexlight  text-[12px] md:text-[14px] xl:text-[20px] pt-3 md:pb-2 lg:pb-6 text-[#C7C5C5]">
                      {item.attributes.SubTitle}
                    </p>
                    <p className="font-plexlight text-[10px] md:text-[11px] lg:text-[14px] xl:text-[16px] pt-3 pb-0  text-[#C7C5C5]">
                      {item.attributes.author}
                    </p>
                    <p className="font-plexlight text-[10px] md:text-[11px] lg:text-[14px] xl:text-[16px] pt-0 pb-5 md:pb-0 lg:pb-3 xl:pb-6  text-[#C7C5C5]">
                      Publication Date: {item.attributes.publishedDate}
                    </p>

                    <a
                      href={item.attributes.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button text={"Read More"} />
                    </a>
                  </div>

                  <div className="hidden md:block md:w-1/2 md:mt-10">
                    <img
                      src={item.attributes.img.data.attributes.url}
                      alt="blog-img"
                    />
                  </div>
                </section>
              )}

              {index === 0 && (
                <p
                  className={`${
                    data.length === 1 ? "hidden" : "block"
                  } xl:container 2xl:mx-auto xl:mx-16 lg:mx-12 md:mx-9 mx-6 pt-10 md:pt-[120px] text-white md:text-[56px] text-[30px] pb-0 md:pb-5 lg:pb-10`}
                >
                  Other News
                </p>
              )}

              {index !== 0 && (
                <section className="xl:container 2xl:mx-auto xl:mx-16 lg:mx-12 md:mx-9 mx-6 pt-8 md:pt-5 lg:pt-[30px] flex flex-row pb-10 md:pb-14 xl:pb-28">
                  <a
                    href={item.attributes.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="flex flex-row">
                      <div className="flex flex-center md:items-center items-start flex-row md:h-auto md:w-auto">
                        <div className="w-1/2 flex justify-center items-center">
                          <img
                            src={item.attributes.img.data.attributes.url}
                            className="w-full xl:h-[25rem] lg:h-[20rem] md:h-[15rem] h-[6.5rem]"
                            alt="blog-img"
                          />
                        </div>
                        <div className="md:bg-white w-1/2 md:h-[90%] flex  flex-col items-start justify-between pl-2 md:p-[20px] lg:p-[30px] xl:p-[42px] text-start">
                          <p className="text-[10px]  md:text-[14px] lg:text-[20px] xl:text-[24px] font-light font-manropemedium text-white md:text-black">
                            {item.attributes.title}
                          </p>
                          <p className="text-[7.5px] md:text-[12px] lg:text-[14px] xl:text-[20px] font-plexlight md:text-[#4D4D4D] text-white py-0.5 md:py-1 truncate-3-lines">
                            {item.attributes.summary}
                          </p>
                          <p className="text-[6px] md:text-[10px] lg:text-[14px] xl:text-[20px] font-plexlight md:text-[#4D4D4D] text-white pt-1 xl:pt-5">
                            {item.attributes.publishedDate}
                          </p>
                        </div>
                      </div>
                    </div>
                  </a>
                </section>
              )}
            </div>
          ))}

          {visibleItems < data.length && (
            <section
              className={`${
                data.length > 2 ? "flex" : "hidden"
              } xl:container 2xl:mx-auto xl:mx-16 justify-center items-center lg:mx-12 md:mx-9 mx-6 py-10 md:py-14 flex flex-col md:flex-row`}
            >
              <div>
                <Button text={"Load more"} onClick={loadMore} />
              </div>
            </section>
          )}
        </>
      )}

      <Closing
        text="We're Recognized for"
        subText={`The Value We Create`}
        ctaText="Start your free trial now!"
      />
    </>
  );
}
